
import img1 from "../components/image/carelia/full.png";


export const carelia = {
    id: 1,
    type: 'web',
    team: ['Татьяна Плющ', 'Ольга Калафат'],
    title: 'Кладовая Карелии',
    tags: '#сайт #интернетМагазин #2023',

    img1: img1,

    color: '#F0eeed',
    textColor: '#168060',
    link: 'https://klad10.ru/'
}