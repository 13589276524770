import img0 from "../components/image/dst/0.gif";
import img1 from "../components/image/dst/1.png";
import img2 from "../components/image/dst/2.gif";
import img3 from "../components/image/dst/3.png";
import img4 from "../components/image/dst/4.gif";
import img5 from "../components/image/dst/5.png";
import img6 from "../components/image/dst/6.png";
import img7 from "../components/image/dst/7.gif";
import img8 from "../components/image/dst/8.png";
import img9 from "../components/image/dst/9.png";
import img10 from "../components/image/dst/10.gif";
import img11 from "../components/image/dst/11.png";
import img12 from "../components/image/dst/12.png";
import img13 from "../components/image/dst/13.gif";
import img14 from "../components/image/dst/14.png";
import img15 from "../components/image/dst/15.gif";
import slide1 from "../components/image/dst/guide/1.webp";
import slide2 from "../components/image/dst/guide/2.webp";
import slide3 from "../components/image/dst/guide/3.webp";
import slide4 from "../components/image/dst/guide/4.webp";
import slide5 from "../components/image/dst/guide/5.webp";
import slide6 from "../components/image/dst/guide/6.webp";
import slide7 from "../components/image/dst/guide/7.webp";
import slide8 from "../components/image/dst/guide/8.webp";
import slide9 from "../components/image/dst/guide/9.webp";
import slide10 from "../components/image/dst/guide/10.webp"; 
import slide11 from "../components/image/dst/guide/11.webp";
import slide12 from "../components/image/dst/guide/12.webp";
import slide13 from "../components/image/dst/guide/13.webp";
import slide14 from "../components/image/dst/guide/14.webp";
import slide15 from "../components/image/dst/guide/15.webp";
import slide16 from "../components/image/dst/guide/16.webp";
import slide17 from "../components/image/dst/guide/17.webp";
import slide18 from "../components/image/dst/guide/18.webp";
import slide19 from "../components/image/dst/guide/19.webp";
import slide20 from "../components/image/dst/guide/20.webp";
import slide21 from "../components/image/dst/guide/21.webp";
import slide22 from "../components/image/dst/guide/22.webp";
import slide23 from "../components/image/dst/guide/23.webp";
import slide24 from "../components/image/dst/guide/24.webp";
import slide25 from "../components/image/dst/guide/25.webp";
import slide26 from "../components/image/dst/guide/26.webp";
import slide27 from "../components/image/dst/guide/27.webp";
import slide28 from "../components/image/dst/guide/28.webp";
import slide29 from "../components/image/dst/guide/29.webp";


export const dst = {
    id: 1,
    type: 'graph',
    team: ['Андрей Миланин', 'Дарья Рогова', 'Семен Елеев', 'Анна Царькова', 'Татьяна Хахаева', 'Екатерина Прокопенко'],
    title: 'Digital Science Team',
    tags: '#лого #фирменныйстиль #маркетинговоеагентство #2023',
    desc: 'Digital Science Team is a team working with comprehensive internet marketing built on two pillars: a love for people and a scientific approach. The logo and identity were designed with modern trends and client requests in mind. The logo is based on asterisks, round and filled round. These symbols representing three forms of the same object, demonstrating dynamism and the ability to adapt to any situation on the market. The identity objects are made up of parts of the logo in various combinations.',
    cover: img0,
    img1: img1,
    img2: img2,
    img3: img3,
    img4: img4,
    img5: img5,
    img6: img6,
    img7: img7,
    img8: img8,
    img9: img9,
    img10: img10,
    img11: img11,
    img12: img12,
    img13: img13,
    img14: img14,
    img15: img15,
    slider: [
        slide1,
        slide2,
        slide3,
        slide4,
        slide5,
        slide6,
        slide7,
        slide8,
        slide9,
        slide10,
        slide11,
        slide12,
        slide13,
        slide14,
        slide15,
        slide16,
        slide17,
        slide18,
        slide19,
        slide20,
        slide21,
        slide22,
        slide23,
        slide24,
        slide25,
        slide26,
        slide27,
        slide28,
        slide29
    ],
    color: '#0e140e',
    textColor: '#f1f1f1'
}