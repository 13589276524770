import React, {useEffect, useState, useRef} from "react";
import { useParams } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Discuss from "../../components/Discuss";
import { dst } from "../../services/dst";
import { cardioDiabet } from "../../services/cardioDiabet";
import { natur } from "../../services/natur";
import { logoDariaPetrova } from "../../services/logoDariaPetrova";
import { siteDariaPetrova } from "../../services/siteDariaPetrova";
import { logunova } from "../../services/logunova";
import { rukovitsyn } from "../../services/rukovitsyn";
import { siteCardioDiabet } from "../../services/siteCardioDiabet";
import { lapki } from "../../services/lapki";
import { ingresso } from "../../services/ingresso";
import { volta } from "../../services/volta";
import { carelia } from "../../services/carelia";
import { mossman } from "../../services/mossman";
import WebDis from "../../components/WebDis";
import GraphDis from "../../components/GraphDis";
import { webDesigners } from "../../services/teamWeb";
import { graphDesigners } from "../../services/teamGraph";
import portfolioItems from "../../services/portfolio";
import PortfolioItem from "../../components/PortfolioItem";
import './styles.scss'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


const Case = () => {
    const {route} = useParams();
    const [web, setWeb] = useState(webDesigners);
    const [graph, setGraph] = useState(graphDesigners);
    const [activeGraph, setActiveGraph] = useState(false);
    const [activeWeb, setActiveWeb] = useState(false);
    const [teamInd, setTeamInd] = useState(0);
    const [popupActive, setPopupActive] = useState(false);
    const [list, setList] = useState(portfolioItems);
    const [indArr, setIndArr] = useState({});

    const [buttonHovered, setButtonHovered] = useState(false)

    const sliderMain = useRef(null);
    const sliderNav = useRef(null);



    const [caseInfo, setCaseInfo] = useState(function(){
        const caseInfoMapping = {
            dst: dst,
            cardioDiabet: cardioDiabet,
            natur: natur,
            logoDariaPetrova: logoDariaPetrova,
            siteDariaPetrova: siteDariaPetrova,
            logunova: logunova,
            rukovitsyn: rukovitsyn,
            siteCardioDiabet: siteCardioDiabet,
            lapki: lapki,
            ingresso: ingresso,
            volta: volta,
            carelia: carelia,
            mossman: mossman
          };
          return caseInfoMapping[route]
    });



    const scrollSlides = (slider, e) => {
        scrollSlides.active = true;
        // e.preventDefault();
        // window.scrollTo(window.scrollY, window.scrollX)
        if (e.deltaY < 0) {
            slider.current.slickNext()
            } else {

            slider.current.slickPrev()
        }

    }

    const settings1 = {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        lazyLoad: 'ondemand',
        swipe: true,
        scrollX: true,
        fade: true,
        asNavFor: sliderNav.current,
        infinite: false,
        initialSlide: 0,
        speed: 400,
        accessibility: true,
        adaptiveHeight: true,
        responsive: [
            {
            breakpoint: 768,
            settings: {
                arrows: true,
                verticalSwiping: true
            }
            }

        ]
    }
    
    const settings2 = {
        slidesToShow: 5,
        slidesToScroll: 6,
        centerMode: true,
        lazyLoad: 'ondemand',
        asNavFor: sliderMain.current,
        dots: false,
        focusOnSelect: true,
        arrows: false,
        verticalSwiping: true,
        swipe: true,
        infinite: false,
        initialSlide: 0,
        speed: 400,
        adaptiveHeight: true,
        responsive: [
            {
            breakpoint: 991,
            settings: {
                slidesToShow: 4,
            }
            },
            {
            breakpoint: 768,
            settings: {
                slidesToShow: 4,
            }
            }

        ]
    }


    // useEffect(()=>{
    //     document.addEventListener('wheel', (e)=>scrollSlides(e));
    
    //     return () =>{
    //         document.removeEventListener('wheel', (e)=>scrollSlides(e));
    
    //     }
    
    //   }, [])

    useEffect(()=> {
        const caseInfoMapping = {
            dst: dst,
            cardioDiabet: cardioDiabet,
            natur: natur,
            logoDariaPetrova: logoDariaPetrova,
            siteDariaPetrova: siteDariaPetrova,
            logunova: logunova,
            rukovitsyn: rukovitsyn,
            siteCardioDiabet: siteCardioDiabet,
            lapki: lapki,
            ingresso: ingresso,
            volta: volta,
            carelia: carelia,
            mossman: mossman
          };
      
        setCaseInfo(caseInfoMapping[route]);
        setIndArr(function(){

            const graphCases = list.filter(item => {
                return item.type == 'graph'
            })
            const webCases = list.filter(item => {
                return item.type == 'web'
            })

            if(route == 'dst' || route == 'cardioDiabet' || route == 'natur' || route == 'logoDariaPetrova' || route == 'logunova' || route == 'rukovitsyn' || route == 'lapki'){
                let randInd1 = Math.floor(Math.random()*graphCases.length);
                let randInd2 = Math.floor(Math.random()*graphCases.length);
                while (randInd1 == randInd2 || graphCases[randInd1].route == route || graphCases[randInd2].route == route){
                    randInd1 = Math.floor(Math.random()*graphCases.length);
                    randInd2 = Math.floor(Math.random()*graphCases.length);
                } 
                return [graphCases[randInd1].id, graphCases[Math.floor(randInd2)].id];
            } else {
                let randInd1 = Math.floor(Math.random()*webCases.length);
                let randInd2 = Math.floor(Math.random()*webCases.length);
                while (randInd1 == randInd2 || webCases[randInd1].route == route || webCases[randInd2].route == route){
                    randInd1 = Math.floor(Math.random()*webCases.length);
                    randInd2 = Math.floor(Math.random()*webCases.length);
                } 
                return [webCases[randInd1].id, webCases[Math.floor(randInd2)].id];
            }
        })

    }, [route])

    const handleClick = (e, id) => {
        if (e.target.className.includes("gallery-item-big")){
            setActiveWeb(true);
        } else {
            setActiveGraph(true);     
        }
        const childArr = e.target.parentNode.childNodes;
        // for (let i=0; i<childArr.length; i++){
        //     if (e.target == childArr[i]){
        //         setTeamInd(i);
        //         return teamInd;
        //     }
        // }
        setTeamInd(id)
        return teamInd, activeWeb, activeGraph;
    }

    

    const closePopup = (e) => {
            setActiveWeb(false);
            setActiveGraph(false);
    }

    const showPopup = () => {
        setPopupActive(true)
    }
    const unshowPopup = () => {
        setPopupActive(false)
    }


    return (
        <section className="caseInfoWrapper">

        <Header/>
        <section className="caseInfo" style={{backgroundColor: caseInfo.color, color: caseInfo.textColor}}>
         <div className="container">
            <h1 className="caseTitle" style={{color: caseInfo.mainTextColor ? caseInfo.mainTextColor :  caseInfo.textColor}}>{caseInfo.title}</h1>
            <p className="caseTags">{caseInfo.tags}</p>
            <div className="caseWrapper">
            <img className='caseCover' src={caseInfo.cover ? caseInfo.cover : ''}></img>
            <div className="caseDesc" style={{margin: caseInfo.desc ? '60px auto' : '0px'}}>{caseInfo.desc ? caseInfo.desc : ''}</div>
            <img src={caseInfo.img1 ? caseInfo.img1: ''}></img>
            <img src={caseInfo.img2 ? caseInfo.img2: ''}></img>
            <img src={caseInfo.img3 ? caseInfo.img3: ''}></img>
            <img src={caseInfo.img4 ? caseInfo.img4: ''}></img>
            <img src={caseInfo.img5 ? caseInfo.img5: ''}></img>
            <div style={route == 'dst' ? {display: 'flex'} : {display: 'block'}}>
                <img src={caseInfo.img6 ? caseInfo.img6: ''}></img>
                <img src={caseInfo.img7 ? caseInfo.img7: ''}></img>
            </div>
            <img src={caseInfo.img8 ? caseInfo.img8: ''}></img>
            <div style={route == 'dst' ? {display: 'flex'} : {display: 'block'}}>
                <img src={caseInfo.img9 ? caseInfo.img9: ''}></img>
                <img src={caseInfo.img10 ? caseInfo.img10: ''}></img>
            </div>

            <img src={caseInfo.img11 ? caseInfo.img11: ''}></img>
            <img src={caseInfo.img12 ? caseInfo.img12: ''}></img>
            <img src={caseInfo.img13 ? caseInfo.img13: ''}></img>
            <img src={caseInfo.img14 ? caseInfo.img14: ''}></img>
            <img src={caseInfo.img15 ? caseInfo.img15: ''}></img>
            </div>

            <div className="projectResult">
                {caseInfo.link ? 
                <div className="project-link">
                    <h2 className="slider-title project-link-title">посмотрите сайт вживую</h2>
                    <div className="project-link-wrap">
                    <div className="project-link-desc">
                        <svg  viewBox="0 0 71 86" fill="none" xmlns="http://www.w3.org/2000/svg" className="project-link-svg">
                            <path d="M32.613 5C30.8026 12.2361 34.4227 50.2257 38.9446 80.0746" stroke={caseInfo.mainTextColor ? caseInfo.mainTextColor :  caseInfo.textColor} strokeWidth="10" strokeLinecap="round"/>
                            <path d="M65.6024 8.61816L5 63.7935" stroke={caseInfo.mainTextColor ? caseInfo.mainTextColor :  caseInfo.textColor} strokeWidth="10" strokeLinecap="round"/>
                            <path d="M6.80859 10.4268C25.5569 32.4518 38.1638 43.3053 62.8884 61.0795" stroke={caseInfo.mainTextColor ? caseInfo.mainTextColor :  caseInfo.textColor} strokeWidth="10" strokeLinecap="round"/>
                        </svg>
                        Сайт — муза для клиента, после передачи мы не контролируем его вдохновение</div>
                    <a href={caseInfo.link} target="_blank" style={{color: buttonHovered ? caseInfo.color : caseInfo.textColor, border: `1px solid ${caseInfo.textColor}`, backgroundColor : buttonHovered ? caseInfo.textColor : caseInfo.color}}
                    onMouseEnter={() => setButtonHovered(true)} 
                    onMouseLeave={() => setButtonHovered(false)} 
                    className="pointer projectResultLink btn">Посмотреть</a> 
                    </div>
                </div>
                : caseInfo.slider ? 

                <>
                <div className="project-slider" 
                // onWheel={(e) => scrollSlides(sliderMain, e)} 
                >
                    <h2 className="slider-title">Гайдбук</h2>
                    <Slider {...settings1} ref={sliderMain}>
                      
                        {caseInfo.slider.map((item, index) => {
                            return <div key={index} className="slider-item"><div 
                            style={{border: `1px solid ${caseInfo.textColor}`}}
                            ><img src={item} alt="" className='slider-img' ></img></div></div> 
                        })}   
                                                   
                    </Slider>
                </div>
                <div className="project-slider-nav" 
                // onWheel={(e) => scrollSlides(sliderNav, e)}
                
                >
                    <Slider {...settings2} ref={sliderNav}>
                        {caseInfo.slider.map((item, index)=> {
                            return <div key={index} className="slider-nav-item"><img src={item} alt="" className="slider-nav-img"></img></div> 
                        })}
                         
                     </Slider>
                     </div>
                </> : '' }
                
            </div>

            <div className="work-team">
                <h2>Над проектом работали</h2>
                <div className="work-team-wrapper">
          
                <div  className={caseInfo.type=='graph' ? 'big-wrapper unshown' : 'big-wrapper'}>
                {web.map(item=>{
                        if(caseInfo.team.includes(item.name)){
                           return(
                            <WebDis
                                key={item.id} item={item} 
                                onClick={(e)=>handleClick(e, item.id)}
                            />) 
                        }
                    })}
                   
                </div>
                <div  className={caseInfo.type=='web' ? 'small-wrapper unshown' : 'small-wrapper'}>
                    {graph.map(item=>{
                        if(caseInfo.team.includes(item.name)){
                           return(
                            <GraphDis
                                key={item.id} item={item} 
                                onClick={(e)=>handleClick(e, item.id)}
                            />) 
                        }
                    })}
                </div>
                </div>
                <div  className={activeWeb ? "gallery-popup-wrapper active": "gallery-popup-wrapper"}>
                    <div  className="overlay gallery-overlay pointer" onClick={closePopup}></div>
                    <div  className="popup gallery-popup">
                        <img  className="person-portrait" src={web[teamInd] ? web[teamInd].portrait : ' '}></img>
                        <div  className="person-info">
                            <p  className="person-name">{web[teamInd].name ? web[teamInd].name : ""}</p>
                        <p  className="person-position">{web[teamInd].position ? web[teamInd].position: ""}</p>
                        </div>
                    </div>
                </div>

                <div  className={activeGraph ? "gallery-popup-wrapper active": "gallery-popup-wrapper"}>
                    <div  className="overlay gallery-overlay pointer" onClick={closePopup}></div>
                    <div  className="popup gallery-popup">
                        <img  className="person-portrait" src={graph[teamInd] ? graph[teamInd].portrait : ""}></img>
                        <div  className="person-info">
                            <p  className="person-name">{graph[teamInd] ? graph[teamInd].name : ""}</p>
                        <p  className="person-position">{graph[teamInd] ? graph[teamInd].position : ""}</p>
                        </div>
                    </div>
                </div>

            </div>

            <div className="look-hear">
                <h2>Загляните сюда</h2>
                <div   className="portfolio-wrapper">
                    {list.map(item=>{
                        if(item.id == indArr[0] || item.id == indArr[1]){
                            return(
                                <PortfolioItem
                                    key={item.id} item={item} textColor={caseInfo.textColor}

                                />
                        )}
                    }
                    )}

                </div>
        </div>

         </div>
         </section>
        <Discuss/>
        <Footer/>
        </section>
    )

}
export default Case;