import React, {useEffect, useRef, useState} from "react";
import { NavLink } from "react-router-dom";
import { routeMain } from "../Main";
import routePrice from "./routes";
import Footer from "../../components/Footer";
import Discuss from "../../components/Discuss";
import { addGraph } from "../../services/addGraph";
import { addWeb } from "../../services/addWeb";
import AddService from "../../components/AddService";

import './styles.css';

const Price = () => {
    const [listGraph, setListGraph] = useState(addGraph);
    const [listWeb, setListWeb] = useState(addWeb);
    const [tarif, setTarif] = useState([35000, 55000, 75000, 35000, 55000, 75000, 105000, 45000, 105000]);
    const [currTarif, setCurrTarif] = useState(tarif);
    const [currency, setCurrency] = useState("RUB"); 
    const [sign, setSign] = useState('₽');
    const [priceValue, setPriceValue] = useState([...listGraph.map(item=> item.price), ...listWeb.map(item=> item.price)]);
    const [currPriceValue, setCurrPriceValue] = useState(priceValue);
    const [rate, setRate] = useState([]);

    const [price, setPrice] = useState(Array.from(document.querySelectorAll('.service-price-item')));

    const [widthArr, setWidthArr] = useState([853, 1057, 945, 1021, 990, 842, 1011, 744, 992, 922, 853, 918, 943, 916, 920, 904, 919, 803, 749, 793, 958, 901, 956, 940]); 

    const countLine = () => { 
        const service= Array.from(document.querySelectorAll('.service'));
        const price = Array.from(document.querySelectorAll('.service-price-item'));
        const wrap = Array.from(document.querySelectorAll('.service-item'));
        for (let i=0; i<price.length; i++){
            let serviceWidth=service[i].offsetWidth;
            let priceWidth=price[i].offsetWidth;
            let wrapWidth=wrap[i].offsetWidth;
            let lineWidth = wrapWidth - serviceWidth - priceWidth-100; 
            widthArr.push(lineWidth);
            let newWidthArr=widthArr.slice(-24); 
            setWidthArr(newWidthArr); 
        }
        
        return widthArr;
    }

    useEffect(()=>{
        countLine()
    }, [currPriceValue.length]) 

    useEffect(()=>{
        fetch("https://api.exchangerate-api.com/v4/latest/RUB")
        .then(res => res.json())
        .then(
            (result)=>{
                setRate(result.rates)
                return rate
            },
            (error) => {
                alert("не удалось загрузить актуальный курс валют")
            }
        )
    }, [])

    const changeCurrency = (e) => {
        setCurrency(e.target.value);
        return currency;
    }

    const changePrice = () =>{
        let newPriceValue;
        let newTarifValue;
        if (currency=="RUB"){
            setSign('₽');
            newTarifValue=tarif.reduce((acc, item)=>{
                acc.push((item*rate[currency]).toFixed(0));
                return acc;        
            }, [])
            newPriceValue = priceValue.reduce((acc, item)=>{
                acc.push((item*rate[currency]).toFixed(0));
                return acc;        
            }, [])


        } else if(currency=="KZT"){
            setSign('₸');
            newTarifValue=tarif.reduce((acc, item)=>{
                switch((item*rate[currency]*0.95).toFixed(0).length){
                    case 6: 
                        acc.push((Math.round((item*rate[currency]*0.95).toFixed(0)/1000)*1000));
                        break;
                    default: acc.push((item*rate[currency]*0.95).toFixed(0));
                }
                return acc;        
            }, [])

            newPriceValue = priceValue.reduce((acc, item)=>{
                switch((item*rate[currency]*0.95).toFixed(0).length){
                    case 6: 
                        acc.push((Math.round((item*rate[currency]*0.9).toFixed(0)/1000)*1000));
                        break;
                    case 5: 
                        acc.push((Math.round((item*rate[currency]*0.9).toFixed(0)/100)*100));
                        break;
                    case 4: 
                        acc.push((Math.round((item*rate[currency]*0.9).toFixed(0)/100)*100));
                        break;
                    default: acc.push((item*rate[currency]*0.9).toFixed(0));
                }
                return acc;        
            }, [])
        } else if(currency=="USD"){
            setSign('$');
            newTarifValue=tarif.reduce((acc, item)=>{
                switch((item*rate[currency]*1.1).toFixed(0).length){
                    case 4: 
                        acc.push((Math.round((item*rate[currency]*1.1).toFixed(0)/100)*100));
                        break;
                    case 3: 
                        acc.push((Math.round((item*rate[currency]*1.1).toFixed(0)/10)*10));
                        break;
                    default: acc.push((item*rate[currency]*1.1).toFixed(0));
                }
                return acc;        
            }, [])

            newPriceValue = priceValue.reduce((acc, item)=>{
                switch((item*rate[currency]).toFixed(0).length){
                    case 3: 
                        acc.push((Math.round((item*rate[currency]).toFixed(0)/10)*100));
                        break;
                    case 2: 
                        acc.push((Math.round((item*rate[currency]).toFixed(0)/10)*10));
                        break;
                    default: acc.push((item*rate[currency]).toFixed(0));
                }
                return acc;        
            }, [])
        }  
        else if(currency=="EUR"){
            setSign('€');
            newTarifValue=tarif.reduce((acc, item)=>{
                switch((item*rate[currency]*1.1).toFixed(0).length){
                    case 4: 
                        acc.push((Math.round((item*rate[currency]*1.1).toFixed(0)/100)*100));
                        break;
                    case 3: 
                        acc.push((Math.round((item*rate[currency]*1.1).toFixed(0)/10)*10));
                        break;
                    default: acc.push((item*rate[currency]*1.1).toFixed(0));
                }
                return acc;        
            }, [])

            newPriceValue = priceValue.reduce((acc, item)=>{
                switch((item*rate[currency]).toFixed(0).length){
                    case 3: 
                        acc.push((Math.round((item*rate[currency]).toFixed(0)/10)*100));
                        break;
                    case 2: 
                        acc.push((Math.round((item*rate[currency]).toFixed(0)/10)*10));
                        break;
                    default: acc.push((item*rate[currency]).toFixed(0));
                }
                return acc;        
            }, [])
        }  

        setCurrPriceValue(newPriceValue);
        setCurrTarif(newTarifValue);
        return currPriceValue, currTarif;
    }
    
    return (
        <>
        <section className="price-head">
         <div className="bg-price"></div>
            <div className="container">
                <div className="price-title-wrapper section-title-wrapper">
            <NavLink to={routeMain()} className='arrow-home pointer'>
                                </NavLink>
                <h2 className="price-header-title">Тарифные предложения</h2>        
                <p className="price-header-subtitle">актуально на 05.2023 года</p>
                </div>
                <div className="currency-select-wrapper">
                    <select name="currency" id="currency" className="currency-select pointer" value={currency} onChange={changeCurrency} onClick={changePrice}>
                        <option value="RUB">RUB</option>
                        <option value="KZT">KZT</option>
                        <option value="USD">USD</option>
                        <option value="EUR">EUR</option>
                    </select>
                </div>
                <h3 className="condition-header-title section-title">Общие условия</h3>
            </div>
            </section>

            <section className="price-summary">
            <div className="container">
            
            <div className="price-summary-rules">
                <div className="rules-column-one rules-column">
                    <h4 className="rules-column-title rules-column-title-one">алгоритм работы</h4>
                    <ol type='1' className="rules rules-num">
                        <li className="rule rule-num">Формирование технического задания; </li>
                        <li className="rule rule-num">Заполнение брифа;</li>
                        <li className="rule rule-num">Подписание договора;</li>
                        <li className="rule rule-num">Внесение предоплаты;</li>
                        <li className="rule rule-num">Комплексный визуальный анализ вашей сферы деятельности; </li>
                        <li className="rule rule-num">Подготовка 2-5 промежуточных презентаций нашей работы;</li>
                        <li className="rule rule-num">Согласование результатов на каждом этапе;</li>
                        <li className="rule rule-num">Внесение остальной части оплаты;</li>
                        <li className="rule rule-num">Передача исходников, подписание акта, передача РИД.</li>
                    </ol>

                </div>
                <div className="rules-column-two rules-column">
                    <h4 className="rules-column-title rules-column-title-two">способы оплаты</h4>
                    <ul className="rules">
                        <li className="rule">Оплата приводится в любой валюте.</li>
                        <li className="rule">Для клиентов из России оплата производится по реквизитам компании, зарегистрированной в России. </li>
                        <li className="rule">Для иностранных клиентов оплата производится по реквизитам компании, зарегистрированной в Казахстане.</li>
                    </ul>
                </div>
            </div>
        </div>
        </section>

        <section className="price-branding">
            <div className="container">
                <h3 className="price-branding-title section-title">Цены на создание графического брендинга</h3>
                <div className="branding-tarifs">
                    <div className="branding-logo branding-item">
                        <div className="tarif-mark">Тариф</div>
                        <div className="tarif-wrap">
                                    <p className="tarif-title">logo</p>
                        <ul className="tarif-desc">
                            <li className="tarif-desc-item">до 1 варианта логотипа;</li>
                            <li className="tarif-desc-item">без правок;</li>
                            <li className="tarif-desc-item">подробный анализ прямых и косвенных конкурентов;</li>
                            <li className="tarif-desc-item">создание мудборда;</li>
                            <li className="tarif-desc-item">подбор референсов логотипов;</li>
                            <li className="tarif-desc-item">анализ цветовой гаммы;</li>
                            <li className="tarif-desc-item">презентация логотипа на нескольких носителях.</li>
                        </ul>
                        </div>
                
                        <div className="tarif-subdesc">
                            <p className="tarif-time">срок разработки - 3 недели</p>
                            <span className="tarif-price">{currTarif[0]} {sign}</span>
                        </div>

                    </div>
                    <div className="branding-messenger branding-item">
                        <div className="tarif-mark">Тариф</div>
                        <div className="tarif-wrap">
                            <p className="tarif-title">logomessenger</p>
                        <ul className="tarif-desc">
                            <li className="tarif-desc-item">до 1 варианта логотипа;</li>
                            <li className="tarif-desc-item">до 1 круга правок;</li>
                            <li className="tarif-desc-item">подробный анализ прямых и косвенных конкурентов;</li>
                            <li className="tarif-desc-item">создание мудборда;</li>
                            <li className="tarif-desc-item">подбор референсов логотипов;</li>
                            <li className="tarif-desc-item">анализ цветовой гаммы;</li>
                            <li className="tarif-desc-item">презентация логотипа на нескольких носителях;</li>
                            <li className="tarif-desc-item">до 2 социальных сетей, для каждой:
                                <ul>
                                    <li className="tarif-desc-item">до 1 обложки;</li>
                                    <li className="tarif-desc-item">до 3 шаблонов историй;</li>
                                    <li className="tarif-desc-item">до 3 шаблонов постов;</li>
                                    <li className="tarif-desc-item">до 1 изображения профиля;</li>
                                    <li className="tarif-desc-item">до 3 иконок.</li>
                                </ul>
                            </li>
                            
                        </ul>
                        </div>
                        
                        <div className="tarif-subdesc">
                            <p className="tarif-time">срок разработки - 4 недели</p>
                            <span className="tarif-price">{currTarif[1]} {sign}</span>
                        </div>


                    </div>
                    <div className="branding-identity branding-item">
                        <div className="tarif-mark">Тариф</div>
                        <div className="tarif-wrap">
                            <p className="tarif-title">logoidentity</p>
                        <ul className="tarif-desc">
                            <li className="tarif-desc-item">до 2 вариантов логотипа;</li>
                            <li className="tarif-desc-item">до 2 кругов правок;</li>
                            <li className="tarif-desc-item">подробный анализ прямых и косвенных конкурентов;</li>
                            <li className="tarif-desc-item">создание мудборда;</li>
                            <li className="tarif-desc-item">подбор референсов логотипов;</li>
                            <li className="tarif-desc-item">подбор референсов айдентики;</li>
                            <li className="tarif-desc-item">анализ цветовой гаммы;</li>
                            <li className="tarif-desc-item">до 10 фирменных носителей;</li>
                            <li className="tarif-desc-item">создание до 3 паттернов;</li>
                            <li className="tarif-desc-item">создание до 3 иллюстраций;</li>
                            <li className="tarif-desc-item">подбор фирменной типографики;</li>
                            <li className="tarif-desc-item">создание цветовой палитры;</li>
                            <li className="tarif-desc-item">до 2 социальных сетей;</li>
                            <li className="tarif-desc-item">разработка гайдбука;</li>
                            <li className="tarif-desc-item">техническая поддержка в течение 1 месяца до 5 обращений.</li>
                            
                        </ul>
                        </div>
                        
                        <div className="tarif-subdesc">
                            <p className="tarif-time">срок разработки - 5 недель</p>
                            <span className="tarif-price">{currTarif[2]} {sign}</span>
                        </div>

                    </div>
                </div>
            </div>


        </section>

        <section className="additional-branding">
            <div className="container">
                <h3 className="additional-brending-title section-title">Цены на дополнительные услуги</h3>
                <div className="additional-services-wrap" id="additional-services-wrap">
                    <div className="additional-services">
                            <div className="service-title-wrap">
                               <p className="service-title">Наименование услуги:</p> 
                               <p className="service-title">Цена:</p> 
                            </div>
                            
                            {listGraph.map((item) => {
                                return(
                                <AddService
                                    key={item.id} 
                                    service={item.service}
                                    price={currPriceValue[item.id]}
                                    width={widthArr[item.id]}
                                    id={item.id}
                                    sign={sign}
                                />)})
                            
                            }
                            
                    </div>
     
                    <div className="additional-service-subdesc">Данный список услуг является не исчерпывающим и может быть дополнен индивидуально под ваши задачи.</div>

                </div>
                </div>
        </section>

        <section className="price-landing">
            <div className="container">
                <h3 className="price-branding-title section-title">Цены на создание одностраничных сайтов</h3>
                <div className="branding-tarifs">
                    <div className="branding-logo branding-item">
                        <div className="tarif-mark">Тариф</div>
                        <div className="tarif-wrap">
                                    <p className="tarif-title">standart</p>
                        <ul className="tarif-desc">
                            <li className="tarif-desc-item">до 1 страницы;</li>
                            <li className="tarif-desc-item">до 10 экранов;</li>
                            <li className="tarif-desc-item">подробный анализ прямых и косвенных конкурентов;</li>
                            <li className="tarif-desc-item">разработка прототипа в Figma;</li>
                            <li className="tarif-desc-item">разработка уникального дизайна в Figma;</li>
                            <li className="tarif-desc-item">перенос макета Figma в тильду;</li>
                            <li className="tarif-desc-item">верстка сайта на «Tilda»;</li>
                            <li className="tarif-desc-item">адаптация под все устройства;</li>
                            <li className="tarif-desc-item">базовая анимация переходов;</li>
                            <li className="tarif-desc-item">базовая настройка SEO;</li>
                            <li className="tarif-desc-item">подключение домена;</li>
                            <li className="tarif-desc-item">до 1 языка.</li>
                        </ul>
                        </div>
                
                        <div className="tarif-subdesc">
                            <p className="tarif-time">срок разработки - 6 недель</p>
                            <span className="tarif-price">{currTarif[3]} {sign}</span>
                        </div>

                    </div>
                    <div className="branding-messenger branding-item">
                        <div className="tarif-mark">Тариф</div>
                        <div className="tarif-wrap">
                            <p className="tarif-title">optimal</p>
                        <ul className="tarif-desc">
                            <li className="tarif-desc-item">до 1 страницы;</li>
                            <li className="tarif-desc-item">до 10 экранов;</li>
                            <li className="tarif-desc-item">подробный анализ прямых и косвенных конкурентов;</li>
                            <li className="tarif-desc-item">разработка прототипа в Figma;</li>
                            <li className="tarif-desc-item">разработка уникального дизайна в Figma;</li>
                            <li className="tarif-desc-item">перенос макета Figma в тильду;</li>
                            <li className="tarif-desc-item">верстка сайта на «Tilda»;</li>
                            <li className="tarif-desc-item">адаптация под все устройства;</li>
                            <li className="tarif-desc-item">анимация контента на сайте;</li>
                            <li className="tarif-desc-item">базовая настройка SEO;</li>
                            <li className="tarif-desc-item">подключение домена;</li>
                            <li className="tarif-desc-item">до 1 языка;</li>
                            <li className="tarif-desc-item">подбор до 20 изображений;</li>
                            <li className="tarif-desc-item">работа с текстовым контентом до 250 слов;</li>
                            <li className="tarif-desc-item">техподдержка «Tilda» в течение 1 месяца до 10 обращений.</li>

                            
                        </ul>
                        </div>
                        
                        <div className="tarif-subdesc">
                            <p className="tarif-time">срок разработки - 8 недель</p>
                            <span className="tarif-price">{currTarif[4]} {sign}</span>
                        </div>


                    </div>
                    <div className="branding-identity branding-item">
                        <div className="tarif-mark">Тариф</div>
                        <div className="tarif-wrap">
                            <p className="tarif-title">advanced</p>
                        <ul className="tarif-desc">
                            <li className="tarif-desc-item">до 1 страницы;</li>
                            <li className="tarif-desc-item">до 10 экранов;</li>
                            <li className="tarif-desc-item">подробный анализ прямых и косвенных конкурентов;</li>
                            <li className="tarif-desc-item">разработка прототипа в Figma;</li>
                            <li className="tarif-desc-item">разработка уникального дизайна в Figma;</li>
                            <li className="tarif-desc-item">перенос макета Figma в тильду;</li>
                            <li className="tarif-desc-item">верстка сайта на «Tilda»;</li>
                            <li className="tarif-desc-item">адаптация под все устройства;</li>
                            <li className="tarif-desc-item">анимация контента на сайте;</li>
                            <li className="tarif-desc-item">базовая настройка SEO;</li>
                            <li className="tarif-desc-item">создание до 5 авторских иллюстраций;</li>
                            <li className="tarif-desc-item">создание до 12 авторских иконок;</li>
                            <li className="tarif-desc-item">подключение домена;</li>
                            <li className="tarif-desc-item">до 1 языка;</li>
                            <li className="tarif-desc-item">подбор до 20 изображений;</li>
                            <li className="tarif-desc-item">работа с текстовым контентом до 250 слов;</li>
                            <li className="tarif-desc-item">техподдержка «Tilda» в течение 1 месяца до 10 обращений;</li>
                            <li className="tarif-desc-item">базовое обучение работы с «Tilda» до 3 консультаций.</li>             
                        </ul>
                        </div>
                        
                        <div className="tarif-subdesc">
                            <p className="tarif-time">срок разработки - 10 недель</p>
                            <span className="tarif-price">{currTarif[5]} {sign}</span>
                        </div>

                    </div>
                </div>
            </div>


        </section>

        <section className="price-fullsites">
            <div className="container">
                <h3 className="price-branding-title section-title">Цены на создание сложных сайтов</h3>
                <div className="branding-tarifs">
                    <div className="branding-logo branding-item">
                        <div className="tarif-mark">Тариф</div>
                        <div className="tarif-wrap">
                                    <p className="tarif-title">premium</p>
                        <ul className="tarif-desc">
                            <li className="tarif-desc-item">до 3 страниц;</li>
                            <li className="tarif-desc-item">до 20 экранов;</li>
                            <li className="tarif-desc-item">подробный анализ прямых и косвенных конкурентов;</li>
                            <li className="tarif-desc-item">разработка прототипа в Figma;</li>
                            <li className="tarif-desc-item">разработка уникального дизайна в Figma;</li>
                            <li className="tarif-desc-item">перенос макета Figma в тильду;</li>
                            <li className="tarif-desc-item">верстка сайта на «Tilda»;</li>
                            <li className="tarif-desc-item">адаптация под все устройства;</li>
                            <li className="tarif-desc-item">анимация контента на сайте;</li>
                            <li className="tarif-desc-item">базовая настройка SEO;</li>
                            <li className="tarif-desc-item">подключение домена;</li>
                            <li className="tarif-desc-item">до 2 языков;</li>
                            <li className="tarif-desc-item">подбор до 20 изображений;</li>
                            <li className="tarif-desc-item">работа с текстовым контентом до 250 слов;</li>
                            <li className="tarif-desc-item">техподдержка «Tilda» в течение 1 месяца до 10 обращений.</li>
                            
                        </ul>
                        </div>
                
                        <div className="tarif-subdesc">
                            <p className="tarif-time">срок разработки - 10 недель</p>
                            <span className="tarif-price">{currTarif[6]} {sign}</span>
                        </div>

                    </div>
                    <div className="branding-messenger branding-item">
                        <div className="tarif-mark">Тариф</div>
                        <div className="tarif-wrap">
                            <p className="tarif-title">online store</p>
                        <ul className="tarif-desc">
                            <li className="tarif-desc-item">подробный анализ прямых и косвенных конкурентов;</li>
                            <li className="tarif-desc-item">разработка прототипа в Figma;</li>
                            <li className="tarif-desc-item">разработка уникального дизайна в Figma;</li>
                            <li className="tarif-desc-item">верстка сайта на «Tilda» или на других платформах;</li>
                            <li className="tarif-desc-item">адаптация под все устройства;</li>
                            <li className="tarif-desc-item">анимация контента на сайте;</li>
                            <li className="tarif-desc-item">базовая настройка SEO;</li>
                            <li className="tarif-desc-item">подключение домена;</li>
                            <li className="tarif-desc-item">создание каталога товаров;</li>
                            <li className="tarif-desc-item">настройка фильтров;</li>
                            <li className="tarif-desc-item">настройка корзины;</li>
                            <li className="tarif-desc-item">подключение механизма оплаты.</li>
                            
                        </ul>
                        </div>
                        
                        <div className="tarif-subdesc">
                            <p className="tarif-time">срок разработки - обговаривается</p>
                            <p className="tarif-value">от <span className="tarif-price">{currTarif[7]} {sign}</span></p> 
                        </div>


                    </div>
                    <div className="branding-identity branding-item">
                        <div className="tarif-mark">Тариф</div>
                        <div className="tarif-wrap">
                            <p className="tarif-title">personal</p>
                        <ul className="tarif-desc">
                            <li className="tarif-desc-item">нестандартные проекты по вашим индивидуальным требованиям;</li>
                            <li className="tarif-desc-item">подбор технических параметров под индвидуальные цели;</li>
                            <li className="tarif-desc-item">интеграция сайта на «WordPress»;</li>
                            <li className="tarif-desc-item">разработка сложных сайтов и web-приложений;</li>
                            <li className="tarif-desc-item">создание личных кабинетов с различным функционалом;</li>
                            <li className="tarif-desc-item">разработка интерфейсных элементом.</li>
                            
                        </ul>
                        </div>
                        
                        <div className="tarif-subdesc">
                            <p className="tarif-time">срок разработки - обговаривается</p>
                            <p className="tarif-value">от <span className="tarif-price">{currTarif[8]} {sign}</span></p> 
                        </div>

                    </div>
                </div>
            </div>


        </section>

        <section className="additional-branding">
            <div className="container">
                <h3 className="additional-brending-title section-title">Цены на дополнительные услуги</h3>
                <div className="additional-services-wrap" id="additional-services-wrap">
                    <div className="additional-services">
                            <div className="service-title-wrap">
                               <p className="service-title">Наименование услуги:</p> 
                               <p className="service-title">Цена:</p> 
                            </div>
                            
                            {listWeb.map((item) => {
                                return(
                                <AddService
                                    key={item.id} 
                                    service={item.service}
                                    price={currPriceValue[item.id]}
                                    width={widthArr[item.id]}
                                    sign={sign}
                                />)})
                            
                            }
                    </div>
     
                    <div className="additional-service-subdesc">Данный список услуг является не исчерпывающим и может быть дополнен индивидуально под ваши задачи.</div>

                </div>
                </div>
        </section>
        <Discuss/>

        <Footer/>


        </>
    )
}

export {routePrice};
export default Price;