import React from "react";

const FormSlider3 = ({desc, onDescChange, descColor}) => {
    return(
    <>
                <div className="form-slider">
                    <div className="form-title-wrapper">
                        <h2 className="form-title-one">сканируем</h2>
                        <div className="form-desc-wrapper">
                            <h2 className="form-title-two">вашу идею</h2>
                            <p className="form-description">
                                Если вы опишите проект, то мы лучше поймём предстоящий фронт работ и придём к вам подготовленными.
                            </p>
                        </div>
                    </div>

                    <div className="form-body">
                        <legend className="form-legend">Кратко опишите <span className="green">суть проекта:</span></legend>
                        <div className="textarea-wrapper">
                            <textarea id="textarea-validate" placeholder="Привет! Я хочу логотип, сложный, как кубик Рубика, и уникальный, как снежинка..." name="description" cols="33" rows="5" value={desc} onChange={onDescChange} style={{borderColor: descColor}}></textarea>
                        </div>
                    </div>
          
                </div>

                
    </>)
}
export default FormSlider3;