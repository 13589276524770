export const addGraph = [
    {
        id: 0,
        service: "дизайн дополнительно 1 варианта логотипа",
        price: 20000,
    },
    {
        id: 1,
        service: "1 круг правок",
        price: 12000,
    },
    {
        id: 2,
        service: "дизайн 1 фирменного носителя",
        price: 1500,
    },
    {
        id: 3,
        service: "создание 1 паттерна",
        price: 3000,
    },
    {
        id: 4,
        service: "создание 1 иллюстрации",
        price: 5000,
    },
    {
        id: 5,
        service: "шаблоны для оформления 1 социальной сети",
        price: 15000,
    },
    {
        id: 6,
        service: "разработка гайдбука",
        price: 25000,
    },
    {
        id: 7,
        service: "техническая поддержка в течение 1 месяца до 5 обращений",
        price: 10000,
    },
    {
        id: 8,
        service: "анимация логотипа 1 шт",
        price: 7000,
    },
    {
        id: 9,
        service: "анимация видеоролика за 1 секунду",
        price: 200,
    }
]
