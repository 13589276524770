import React from "react";

const AddService = ({service, price, width, wrapWidth, serviceWidth, priceWidth, sign}) => {
    return(
        <div className="service-item" id="service-item" ref={wrapWidth}>
            <div className="service" ref={serviceWidth}>{service}</div>
            <div className="service-price-line" style={{width: `${width}px`}}></div>
            <div className="service-price-item price-value" id="service-price-item" ref={priceWidth}>{price} {sign}</div>
        </div>
    )
}
export default AddService;