import React from "react";

const FormSlider1 = ({logoChecked, changeChecked, siteChecked, motionChecked, marketingChecked, otherChecked, checkboxIsValid}) => {
    return(
    <>
          <div className="form-slider">
                    <div className="form-title-wrapper">
                        
                        <h2 className="form-title-one">давайте обсудим</h2>
                        <div className="form-desc-wrapper">
                            <h2 className="form-title-two">ваш проект</h2>
                            <p className="form-description">
                                Можно выбрать несколько вариантов, а мы подберем подходящий тариф 
                            </p>
                        </div>
                    </div> 
                    <div className="form-body" id="checkbox-validate"> 
                        <legend className="form-legend">Мы можем всё, однако уточните, <span className="green">какие услуги</span> вас интересуют?</legend>
                        <div className="checkbox-wrapper">
                            <input type="checkbox" id="logo" name="service" className="checkbox pointer" value="logo" checked={logoChecked} onChange={changeChecked}></input>
                            <label htmlFor="logo" className="pointer">логотип/фирменный стиль</label>
                        </div>
                        <div className="checkbox-wrapper">
                            <input type="checkbox" id="site" name="service" className="checkbox pointer" value="site" checked={siteChecked} onChange={changeChecked}></input>
                            <label htmlFor="site" className="pointer">сайт</label>
                        </div>
                        <div className="checkbox-wrapper">
                            <input type="checkbox" id="motion" name="service" className="checkbox pointer" value="motion" checked={motionChecked} onChange={changeChecked}></input>
                            <label htmlFor="motion" className="pointer">моушен-дизайн</label>
                        </div>
                        <div className="checkbox-wrapper">
                            <input type="checkbox" id="marketing" name="service" className="checkbox pointer" value="marketing" checked={marketingChecked} onChange={changeChecked}></input>
                            <label htmlFor="marketing" className="pointer">маркетинг</label>
                        </div>
                        <div className="checkbox-wrapper">
                            <input type="checkbox" id="other" name="service" className="checkbox pointer" value="other" checked={otherChecked} onChange={changeChecked}></input>
                            <label htmlFor="other" className="pointer">другое</label>
                        </div>
                        <div className="checkbox-error">{checkboxIsValid ? "" : "Выберите хотя бы один вариант"}</div>
                    </div>
                    
                    
                </div>


                
    </>)
}
export default FormSlider1;