import React, {useEffect, useState} from "react";
import { NavLink } from "react-router-dom";
import { routeMain } from "../Main";
import routePortfolio from "./routes";
import portfolioItems from "../../services/portfolio";
import PortfolioItem from "../../components/PortfolioItem";
import Discuss from "../../components/Discuss";
import Footer from "../../components/Footer";

import './styles.css';


const Portfolio = () => {

    const [list, setList] = useState(portfolioItems);
    const logoArr = portfolioItems.filter(item => item.filter.split(" ").includes("logo"));
    const siteArr = portfolioItems.filter(item => item.filter.split(" ").includes("site"));
    const brandArr = portfolioItems.filter(item => item.filter.split(" ").includes("brand"));
    const motionArr = portfolioItems.filter(item => item.filter.split(" ").includes("motion"));
    const allArr = portfolioItems.filter(item => item.filter.split(" ").includes("all"));


    const [filterOpen, setFilterOpen] = useState(false);
    const [filterName, setFilterName] = useState('all');


    const filter = () => {
        setFilterOpen(!filterOpen);

        const onClick = e => {
            if (!e.target.className.includes('filter')){
                setFilterOpen(false)
            } 
        }
        document.addEventListener('click', onClick);
        return () => document.removeEventListener('click', onClick);
    }

    const handleFilter = (e) => {
        const filterValue = e.target.className.split(' ')[1];
        if(filterValue.length == 0){
            setFilterName('all')
        } else {
            setFilterName(filterValue); 
        }
        const newList = portfolioItems.filter(item => item.filter.split(" ").includes(filterValue));
        setList(newList);
        return list, filterName;
    }

    const setActive = (e) => {
        e.target.parentNode.childNodes.forEach(item => {
            if(item.classList.contains('active')){
                item.classList.remove("active");
            }
        });
        e.target.classList.add("active");
    }


    return(
        <>
          <section   className="portfolio">
        <div   className="container">
            <div   className="portfolio-title-wrapper section-title-wrapper">

            <NavLink to={routeMain()} className='arrow-home pointer'>
                                </NavLink>
                <h2   className="portfolio-title">портфолио</h2>  
                <div   className="portfolio-filter-wrapper">
                    <button   className="filter-open pointer" id="filter-open" onClick={filter}>фильтр</button> 

                    <div   className={filterOpen ? "filter-btn-wrapper opened" : "filter-btn-wrapper"} onClick={handleFilter}>
                        <button   className="filter-btn logo pointer" onClick={setActive}>логотипы
                            <span>&nbsp;({logoArr.length})</span>
                        
                        </button>
                        <button   className="filter-btn site pointer" onClick={setActive}>сайты
                            <span>&nbsp;({siteArr.length})</span>
                        </button>
                        <button   className="filter-btn brand pointer" onClick={setActive}>фирменный стиль
                            <span>&nbsp;({brandArr.length})</span>
                        </button>
                        <button   className="filter-btn motion pointer" onClick={setActive}>моушен-дизайн
                            <span>&nbsp;({motionArr.length})</span>
                        </button>
                        <button   className="filter-btn active all pointer" onClick={setActive}>все
                            <span>&nbsp;({allArr.length})</span>
                        </button>
                    </div>
                </div>
            </div>
            <div   className="portfolio-wrapper">
            {list.map(item=>{
                    return(
                        <PortfolioItem
                            key={item.id} item={item}
                        />
                    )}
                )}

            </div>
        </div>
    </section>
    <Discuss/>
    <Footer/>
        </>
    )
}

export {routePortfolio};
export default Portfolio