import img0 from "../components/image/natur/0.gif";
import img1 from "../components/image/natur/1.png";
import img2 from "../components/image/natur/2.png";
import img3 from "../components/image/natur/3.png";
import img4 from "../components/image/natur/4.png";
import img5 from "../components/image/natur/5.png";
import img6 from "../components/image/natur/6.png";
import img7 from "../components/image/natur/7.png";
import img8 from "../components/image/natur/8.png";
import img9 from "../components/image/natur/9.png";
import img10 from "../components/image/natur/10.gif";
import img11 from "../components/image/natur/11.png";
import img12 from "../components/image/natur/12.png";
import slide1 from "../components/image/natur/guide/1.webp";
import slide2 from "../components/image/natur/guide/2.webp";
import slide3 from "../components/image/natur/guide/3.webp";
import slide4 from "../components/image/natur/guide/4.webp";
import slide5 from "../components/image/natur/guide/5.webp";
import slide6 from "../components/image/natur/guide/6.webp";
import slide7 from "../components/image/natur/guide/7.webp";
import slide8 from "../components/image/natur/guide/8.webp";
import slide9 from "../components/image/natur/guide/9.webp";
import slide10 from "../components/image/natur/guide/10.webp";
import slide11 from "../components/image/natur/guide/11.webp";
import slide12 from "../components/image/natur/guide/12.webp";
import slide13 from "../components/image/natur/guide/13.webp";
import slide14 from "../components/image/natur/guide/14.webp";
import slide15 from "../components/image/natur/guide/15.webp";
import slide16 from "../components/image/natur/guide/16.webp";
import slide17 from "../components/image/natur/guide/17.webp";
import slide18 from "../components/image/natur/guide/18.webp";
import slide19 from "../components/image/natur/guide/19.webp";
import slide20 from "../components/image/natur/guide/20.webp";
import slide21 from "../components/image/natur/guide/21.webp";
import slide22 from "../components/image/natur/guide/22.webp";
import slide23 from "../components/image/natur/guide/23.webp";
import slide24 from "../components/image/natur/guide/24.webp";
import slide25 from "../components/image/natur/guide/25.webp";
import slide26 from "../components/image/natur/guide/26.webp";
import slide27 from "../components/image/natur/guide/27.webp";
import slide28 from "../components/image/natur/guide/28.webp";
import slide29 from "../components/image/natur/guide/29.webp";
import slide30 from "../components/image/natur/guide/30.webp";
import slide31 from "../components/image/natur/guide/31.webp";
import slide32 from "../components/image/natur/guide/32.webp";
import slide33 from "../components/image/natur/guide/33.webp";
import slide34 from "../components/image/natur/guide/34.webp";


export const natur = {
    id: 1,
    type: 'graph',
    team: ['Андрей Миланин', 'Дарья Рогова', 'Семен Елеев', 'Анна Царькова', 'Татьяна Хахаева', 'Екатерина Прокопенко'],
    title: 'НАТЮР',
    tags: '#лого #фирменныйстиль #экомастерская #2022',
    desc: 'Эко-мастерская Натюр изготавливает разные элементы декора из натуральных природных материалов: дерева, мха, воска и масел. Логотип и фирменный стиль сделан с отсылками к самому теплому и домашнему: верхняя часть логотипа напоминает крышу дома, что создает ощущение уюта,а легкое написание и незавершенность линий леттеринга отсылает к ручной работе. Простые формы говорят о природности и незамысловатости материалов, используемых в мастерской.Кроме того, название - это сокращение от имени владелицы мастерской, НАТальи ЮРьевой.',
    cover: img0,
    img1: img1,
    img2: img2,
    img3: img3,
    img4: img4,
    img5: img5,
    img6: img6,
    img7: img7,
    img8: img8,
    img9: img9,
    img10: img10,
    img11: img11,
    img12: img12,
    slider: [
        slide1,
        slide2,
        slide3,
        slide4,
        slide5,
        slide6,
        slide7,
        slide8,
        slide9,
        slide10,
        slide11,
        slide12,
        slide13,
        slide14,
        slide15,
        slide16,
        slide17,
        slide18,
        slide19,
        slide20,
        slide21,
        slide22,
        slide23,
        slide24,
        slide25,
        slide26,
        slide27,
        slide28,
        slide29,
        slide30,
        slide31,
        slide32,
        slide33,
        slide34
    ],
    color: '#f8fdff',
    textColor: '#2c5b55'
}