import React from "react";
import cardio from "../components/image/cardio.gif";
import natur from "../components/image/natyur.gif";
import logunova from "../components/image/logunova.gif";
import petrovaWeb from "../components/image/petrova_web.gif";
import petrova from "../components/image/petrova.gif";
import dst from "../components/image/dst.gif";
import rukovitsyn from "../components/image/rukovitsyn.gif";
import lapki from "../components/image/lapki/0.gif";
import siteCardio from "../components/image/siteCardioDiabet/0.png";
import ingresso from "../components/image/ingresso/0.png";
import volta from "../components/image/volta/0.png";
import carelia from "../components/image/carelia/1.png";
import mossman from "../components/image/mossman/0.png"; 

 
const portfolioItems = [
    {
      id: 1,
      type: 'graph',
      tags: '#лого #фирменныйстиль #экомастерская #2022',
      title: 'Натюр',
      route: 'natur',
      cover: natur,
      link: 'https://www.behance.net/gallery/164725235/Natur-branding-for-eco-workshop',
      filter: 'portfolio-item all logo brand'
    },
    {
        id: 2,
        type: 'graph',
        tags: '#лого #фирменныйстиль #коучипсихолог #2023',
        title: 'Вера Логунова',
        route: 'logunova',
        cover: logunova,
        link: 'https://www.behance.net/gallery/165620301/Vera-Logunova-coachs-personal-brand',
        filter: 'portfolio-item all logo brand'
    },
    {
      id: 3,
      type: 'web',
      tags: '#сайт #лендинг #юрист #2023',
      title: 'Daria Petrova',
      route: 'siteDariaPetrova',
      cover: petrovaWeb,
      link: 'https://www.behance.net/gallery/165319091/sajt-dlja-korporativnogo-jurista',
      filter: 'portfolio-item all site'
    },
    {
      id: 4,
      type: 'graph',
      tags: '#лого #фирменныйстиль #юрист #2022',
      title: 'Daria Petrova',
      route: 'logoDariaPetrova',
      cover: petrova,
      link: 'https://www.behance.net/gallery/163276887/darja-petrova-lichnyj-brend-jurista-praktika',
      filter: 'portfolio-item all logo brand'
  },
    {
      id: 5,
      type: 'graph',
      tags: '#лого #фирменныйстиль #маркетинговоеагентство #2023',
      title: 'Digital Science Team',
      route: 'dst',
      cover: dst,
      link: 'https://www.behance.net/gallery/166281211/DST-marketing-agency-branding',
      filter: 'portfolio-item all logo brand motion'
    },
    {
      id: 6,
      type: 'graph',
      tags: '#лого #фирменныйстиль #кардиодиабет #2023',
      title: <>Кардио<br></br>диабет</>,
      route: 'cardioDiabet',
      cover: cardio,
      link: 'https://www.behance.net/gallery/167808637/National-Medical-Association-of-Cardiodiabetologists',
      filter: 'portfolio-item all logo brand motion'
    },
    {
      id: 7,
      type: 'web',
      tags: '#сайт #многостраничный #кардиодиабет #2023',
      title: <>Кардио<br></br>диабет</>,
      route: 'siteCardioDiabet',
      cover: siteCardio,
      link: 'https://www.behance.net/gallery/182584679/sajt-dlja-nacionalnoj-associacii-kardiodiabetologov',
      filter: 'portfolio-item all site'
    },
    {
      id: 8,
      type: 'web',
      tags: '#сайт #интернетМагазин #2023',
      title: 'Кладовая Карелии',
      route: 'carelia',
      cover: carelia,
      link: '',
      filter: 'portfolio-item all site'
    },
    {
      id: 9,
      type: 'web',
      tags: '#сайт #лендинг #дизайнИнтерьера #2023',
      title: 'Ingresso',
      route: 'ingresso',
      cover: ingresso,
      link: 'https://www.behance.net/gallery/183583585/redizajn-sajtadlja-studii-dizajna-interera-INGRESSO',
      filter: 'portfolio-item all site'
    },
    {
      id: 10,
      type: 'graph',
      tags: '#лого #фирменныйстиль #онлайн-школа #2023',
      title: <>RUKOVIT<br></br>SYN'<br></br>SCHOOL</>,
      route: 'rukovitsyn',
      cover: rukovitsyn,
      link: 'https://www.behance.net/gallery/182863335/firmennyj-stil-dlja-onlajn-shkoly-rukovitsynschool',
      filter: 'portfolio-item all logo brand motion'
    },
    {
      id: 11,
      type: 'web',
      tags: '#сайт #volta #2023',
      title: 'Volta',
      route: 'volta',
      cover: volta,
      link: '',
      filter: 'portfolio-item all site'
    },
    {
      id: 12,
      type: 'graph',
      tags: '#лого #фирменныйстиль #приютдляживотных #2023',
      title: 'У меня лапки',
      route: 'lapki',
      cover: lapki,
      link: 'https://www.behance.net/gallery/181292293/firmennyj-stil-dlja-prijuta-u-menja-lapki',
      filter: 'portfolio-item all logo brand motion'
    },
    {
      id: 13,
      type: 'web',
      tags: '#сайт #mossman #2023',
      title: 'Mossman',
      route: 'mossman',
      cover: mossman,
      link: '',
      filter: 'portfolio-item all site'
    }
    
]
portfolioItems.reverse();

export default portfolioItems;