import React from "react";
import { NavLink } from 'react-router-dom';
import { routePrivacy } from '../../pages/Privacy';

const FormSlider4 = ({name, country, utc, phone, mail, onNameChange, nameColor, onCountryChange, countryColor, onUtcChange, onMailChange, onPhoneChange, phoneColor, mailColor, policyChecked, onPolicyChange}) => {
    return(
    <>
          


                    <div className="form-title-wrapper">
                        <h2 className="form-title-one">финальный шаг</h2>
                        <div className="form-desc-wrapper">
                            <h2 className="form-title-two">до шедевра</h2>
                            <p className="form-description">
                                Нажмите на кнопку «отправить», и мы сразу начнем работать над вашим проектом!
                            </p>
                        </div>
                    </div>

                    <div className="form-body" id="input-validate">
                        <legend className="form-legend form-legend-contact">Контактные данные</legend>
                        <input type="text" placeholder="Имя" name="name" id="name-validate" value={name} onChange={onNameChange} style={{borderColor: nameColor}}></input>
                        <div className="country">
                            <input type="text" placeholder="Страна" name="country" id="country-validate" value={country} onChange={onCountryChange} style={{borderColor: countryColor}}></input>
                            <div className="select-wrapper pointer">
                            <select name="utc" id="utc-select" value={utc} onChange={onUtcChange} className="pointer" >
                                    <option value="utc+7">UTC +7</option>
                                    <option value="utc+6">UTC +6</option>
                                    <option value="utc+5">UTC +5</option>
                                    <option value="utc+4">UTC +4</option>
                                    <option value="utc+3">UTC +3</option>
                                    <option value="utc+2">UTC +2</option>
                                    <option value="utc+1">UTC +1</option>
                                    <option value="utc0">UTC 0</option>
                                    <option value="utc-1">UTC -1</option>
                            </select>
                            <div className="select-arrow pointer"></div>


                            </div>
                            
                        </div>
                        <input type="tel" placeholder="Номер телефона" name="phone" id="phone-validate" value={phone} onChange={onPhoneChange} style={{borderColor: phoneColor}}></input>
                        <input type="email" placeholder="E-mail" name="email" id="mail-validate" value={mail} onChange={onMailChange} style={{borderColor: mailColor}}></input>
                        <div className="checkbox-wrapper checkbox-wrapper-policy">
                            <input type="checkbox" name="policy" id="policy" value='policy' checked={policyChecked} onChange={onPolicyChange}></input>
                            <label htmlFor="policy">Я согласен с <NavLink to={routePrivacy()} className='pointer'>
                                    политикой конфиденциальности
                                </NavLink></label>
                        </div>
                    </div>
                    
    </>)
}
export default FormSlider4;