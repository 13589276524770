import React from "react";


const WebDis = ({item, style, onClick}) => {
    return(
        <img  className="gallery-item-big gallery-item pointer" src={item.img} style={style} onClick={onClick}></img>
    )

}

export default WebDis;