import React, {useEffect} from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import './styles.css';
import Header from '../Header';
import MainPage, {routeMain} from '../../pages/Main';
import Portfolio, {routePortfolio} from '../../pages/Portfolio';
import About, {routeAbout} from '../../pages/About';
import DiscussPage, {routeDiscuss} from '../../pages/Discuss';
import Privacy, {routePrivacy} from '../../pages/Privacy';
import Price, {routePrice} from '../../pages/Price';
import NotFound from '../../pages/NotFound';
import Case from '../../pages/Case/Case';
import { useLocation } from "react-router-dom";
import CookieBar from '../Cookies';


const AppContent = ({mouseOverEvent, mouseOutEvent}) => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);


  return (
    <div className="mainWrapper" onMouseOut={mouseOutEvent} onMouseOver={mouseOverEvent}>
        <Header/>
        <main>
            <Switch>
            <Route exact path = {routeMain()} component={MainPage}/>
            <Route exact path = {routePortfolio()} component={Portfolio}/>
            <Route exact path = {routeAbout()} component={About}/>
            <Route exact path = {routeDiscuss()} component={DiscussPage}/>
            <Route exact path = {routePrivacy()} component={Privacy}/>
            <Route exact path = {routePrice()} component={Price}/>
            <Route exact path = "/:route" component={Case}/>
            
            {/* <Redirect
                    to = {{
                    pathname: routeMain()
                 }}
                 /> */}
            <Route exact path = "*" component={NotFound}/>
            </Switch>
            <CookieBar/>
        </main>     
        
    </div>
  );
}





export default AppContent;

