import React, {useEffect, useState} from "react";
import { NavLink } from "react-router-dom";
import { routeMain } from "../Main";
import routeAbout from "./routes";
import Discuss from "../../components/Discuss";
import { aboutSlider } from "../../services/aboutSlider";
import { webDesigners } from "../../services/teamWeb";
import { graphDesigners } from "../../services/teamGraph";
import WebDis from "../../components/WebDis";
import GraphDis from "../../components/GraphDis";
import Footer from "../../components/Footer";
import './styles.css';
 
const About = () => {

    const [slides, setSlides] = useState(aboutSlider);
    const [index, setIndex] = useState(0);
    const [web, setWeb] = useState(webDesigners);
    const [graph, setGraph] = useState(graphDesigners);
    const [graphScale, setGraphScale] = useState(1);
    const [webScale, setWebScale] = useState(1);
    const [activeGraph, setActiveGraph] = useState(false);
    const [activeWeb, setActiveWeb] = useState(false);
    const [teamInd, setTeamInd] = useState(0);
    const [popupActive, setPopupActive] = useState(false)


    // useEffect(()=> {
    //     setSlides(aboutSlider);
    //     setWeb(webDesigners);
    //     setGraph(graphDesigners);
    //     setIndex(0);
    // }, [])


    const nextSlide = () => {
        const newIndex=index+1;
        setIndex(newIndex);
        if (index == 4){
            setIndex(0);
        }
        return index;
    }
    const prevSlide = () => {
        const newIndex=index-1;
        setIndex(newIndex);
        if (index == 0){
            setIndex(4);
        }
        return index;
    }


    let webStyle = {
        transform: `scale(${webScale})`, 
    }
    let graphStyle = {
        transform: `scale(${graphScale})`,   
    }

    useEffect(() =>{
        const changeScale = setInterval(() => {
            setWebScale(0.28);
            setGraphScale(3.6);
            if(window.screen.width<1490){
                setWebScale(0.33);
                setGraphScale(3);
              }
              if(window.screen.width<1199){
                setWebScale(0.39);
                setGraphScale(2.6);
              }
              if(window.screen.width<767){
                setWebScale(0.33);
                setGraphScale(3);
              }
        }, 2500);
        return () => {
            clearInterval(changeScale);
        }
    }, [webScale, graphScale])

    useEffect(() =>{
        const returnScale = setInterval(() => {
            setWebScale(1);
            setGraphScale(1)
        }, 3000);
        return () => {
            clearInterval(returnScale);
        }
    }, [webScale, graphScale])

    const handleClick = (e) => {
        if (e.target.className.includes("gallery-item-big")){
            setActiveWeb(true);
        } else {
            setActiveGraph(true);     
        }
        const childArr = e.target.parentNode.childNodes;
        for (let i=0; i<childArr.length; i++){
            if (e.target == childArr[i]){
                setTeamInd(i);
                return teamInd;
            }
        }
        return teamInd, activeWeb, activeGraph;
    }

    

    const closePopup = (e) => {
            setActiveWeb(false);
            setActiveGraph(false);
    }

    const showPopup = () => {
        setPopupActive(true)
    }
    const unshowPopup = () => {
        setPopupActive(false)
    }
    

    return(
        <>
          <section  className="about-main">
        <div  className="container">
            <div  className="about-title-wrapper section-title-wrapper">
                
            <NavLink to={routeMain()} className='arrow-home pointer'>
                                </NavLink>
                <h2  className="about-title">Об агентстве</h2>
            </div>
            
        
        <div  className="about-slider-wrapper">
            <div  className="about-slide">
                <div className="slider-text-wrapper">
                    <div className={slides[index].starClass}></div>
                    <h3  className="slide-title">{slides[index].title}</h3>
                </div>
                <p  className="slide-text">{slides[index].body}</p>
            </div>
            <div  className="slider-arrow">
                <div  className="arrow-next arrow pointer" id="arrow-next-about" onClick={nextSlide}></div>
                <div  className="arrow-prev arrow pointer" id="arrow-prev-about" onClick={prevSlide}></div>
            </div>
        </div>
    </div>
    </section>
    <section  className="work">
        <div  className="container">
            <h3  className="work-title">о работе</h3>
            <div  className="about-wrapper">
                <div  className="about-item-wrapper">
                    <div  className="about-item">Мы ваши помощники, создаем ne.простой дизайн, а мега-супер продуманный и выгодный!</div>
                    <div  className="about-item">Не оставляем вас <br/> с пустыми карманами, <br/>наша магия — ваша прибыль.</div>
                </div>
                <div  className="about-item-wrapper">
                    <div  className="about-item">Мы разрабатываем план, адаптированный к вашим конкретным потребностям.</div>
                    <div  className="about-item">Наша команда — это лучший выбор вашего бренда, и не задавайте вопросов.</div>
                </div>
                <div  className="about-item-wrapper">
                    <div  className="about-item">Логотипы, фирменный  стиль, сайты: от простых чудес до эпических шедевров</div>
                </div>
            </div>

        </div>
    </section>

    <section  className="proccess">
        <div  className="container">
            <h3  className="proccess-title">Процесс сотрудничества</h3>
            <div  className="proccess-wrapper">
                <div  className="proccess-item">Формирование технического задания и заполнение брифа</div>
                <div  className="proccess-item">Заключение договора и внесение предоплаты в размере 30% от стоимости проекта</div>
                <div  className="proccess-item">Комплексное исследование для вашего бренда</div>
                <div  className="proccess-item">Презентация промежуточных итогов и согласование результатов на каждом этапе</div>
                <div  className="proccess-item">Официальная передача продукта, оформление сопутствующей документации</div>
            </div>
        </div>
    </section>

    <section  className="team"> 
        
        <div  className="container">
            <h3  className="team-title">Команда</h3>
            <p  className="team-desc">Большая команда для впечатляющих проектов</p>
            <div  className="team-gallery">
                <div  className={activeWeb ? "gallery-popup-wrapper active": "gallery-popup-wrapper"}>
                    <div  className="overlay gallery-overlay pointer" onClick={closePopup}></div>
                    <div  className="popup gallery-popup">
                        <img  className="person-portrait" src={web[teamInd] ? web[teamInd].portrait : ' '}></img>
                        <div  className="person-info">
                            <p  className="person-name">{web[teamInd].name ? web[teamInd].name : ""}</p>
                        <p  className="person-position">{web[teamInd].position ? web[teamInd].position: ""}</p>
                        </div>
                    </div>
                </div>

                <div  className={activeGraph ? "gallery-popup-wrapper active": "gallery-popup-wrapper"}>
                    <div  className="overlay gallery-overlay pointer" onClick={closePopup}></div>
                    <div  className="popup gallery-popup">
                        <img  className="person-portrait" src={graph[teamInd] ? graph[teamInd].portrait : ""}></img>
                        <div  className="person-info">
                            <p  className="person-name">{graph[teamInd] ? graph[teamInd].name : ""}</p>
                        <p  className="person-position">{graph[teamInd] ? graph[teamInd].position : ""}</p>
                        </div>
                    </div>
                </div>
                
                <div  className="big-wrapper">
                    {web.map(item=>{
                        return(
                        <WebDis
                            key={item.id} item={item} style={webStyle}
                            onClick={handleClick}
                        />)
                    })}
                </div>
                <div  className="small-wrapper">
                    {graph.map(item=>{
                        return(
                        <GraphDis
                            key={item.id} item={item} style={graphStyle}
                            onClick={handleClick}
                        />)
                    })}
                </div>
                <div  className="team-button pointer" onClick={showPopup}>
                    <p  className="team-button-text">здесь может быть твоё фото</p>
                    <p  className="team-button-cta">отправить резюме</p>
                </div>
                <div  className={popupActive ? "team-plug-wrapper active" : "team-plug-wrapper"}  >
                    <div  className="overlay pointer" onClick={unshowPopup}></div>
                    <div  className="team-plug">
                        <p  className="plug-text">На данный момент набор сотрудников в студию не ведется</p>
                    </div>
                    
                </div>
            </div>     
               <div  className="team-background"></div> 
            </div>
        
    </section>
    <Discuss/>
    <Footer/>


        </>
    )
}

export {routeAbout};
export default About 