import React, {useEffect, useRef, useState} from 'react';
import './styles.css';
import AppContent from '../AppContent';

const App = () => {

  const authenticate = () => new Promise((resolve) => setTimeout(resolve, 1500)) // 2 seconds

  useEffect(() => {
    authenticate().then(() => {
      const ele = document.getElementById('ipl-progress-indicator')
      if (ele) {
        // fade out
        ele.classList.add('available')
        setTimeout(() => {
          // remove from DOM
          ele.remove()
        }, 2000)
      }
    })
  }, [])



  const delay = 5;
  const dot = useRef(null);
  const circule = useRef(null);
  const cursorVisible = useRef(true);
  const cursorEnlarged = useRef(false);
  const endX = useRef(window.innerWidth/2);
  const endY = useRef(window.innerHeight/2);
  const _x = useRef(0);
  const _y = useRef(0);
  const [dotY, setDotY] = useState(endY.current); 

  const requestRef = useRef(null);

  useEffect(()=>{
    document.addEventListener('mousedown', mouseOverEvent);
    document.addEventListener('mouseup', mouseOutEvent);
    document.addEventListener('mousemove', mouseMoveEvent);
    //document.addEventListener('mousemove', updateCursorPosition);
    document.addEventListener('mouseenter', mouseEnterEvent);
    document.addEventListener('mouseleave', mouseLeaveEvent);
    //document.addEventListener('scroll', scrollEvent);
    animateCircule();

    return () =>{
      document.removeEventListener('mousedown', mouseOverEvent);
      document.removeEventListener('mouseup', mouseOutEvent);
      document.removeEventListener('mousemove', mouseMoveEvent);
      document.removeEventListener('mouseenter', mouseEnterEvent);
      document.removeEventListener('mouseleave', mouseLeaveEvent);
      //document.removeEventListener('scroll', scrollEvent);
      //document.removeEventListener('mousemove', updateCursorPosition);

      cancelAnimationFrame(requestRef.current);

    }

  }, [])


  const toggleCursorVisibility = () => {
      if (cursorVisible.current){
          dot.current.style.opacity = 1;
          circule.current.style.opacity = 1;
      } else {
          dot.current.style.opacity = 1;
          circule.current.style.opacity = 1;
      }
  }

  const toggleCursorSize = () => {
      if (cursorEnlarged.current){
          dot.current.style.transform = 'translate(-50%, -50%) scale(1)';
          circule.current.style.transform = 'translate(-50%, 50%) scale(1)';
      } else {
          dot.current.style.transform = 'translate(-50%, -50%) scale(1)';
          circule.current.style.transform = 'translate(-50%, 50%) scale(1)';
      }
  }

  const mouseOverEvent = (e) => {
      cursorEnlarged.current = true;
      toggleCursorSize();
      if(e.target.classList.contains('pointer')){
        circule.current.classList.add('cursor-pointer');
        dot.current.classList.add('cursor-pointer')
      } else {
          circule.current.classList.remove('cursor-pointer');
          dot.current.classList.remove('cursor-pointer');
        }
  }
  const mouseOutEvent = (e) =>{
      cursorEnlarged.current = false;
      toggleCursorSize();
  }

  const mouseEnterEvent = () => {
      cursorVisible.current = true;
      toggleCursorVisibility();
  }
  const mouseLeaveEvent = () => {
      cursorVisible.current = false;
      toggleCursorVisibility();
  }
  // const scrollEvent = (e) =>{
  //   cursorVisible.current = true;
  //   toggleCursorVisibility();
  //   //mouseMoveEvent(e);
  //   // let dotY = mouseMoveEvent(e)/100;
  //   let _scrollY = window.scrollY;
  //   console.log('а это dotY в scroll ' + dotY);
  //   let coordY = dotY + _scrollY;
  //   console.log('это старая координата курсора ' + dotY)
  //   console.log('это скролл ' + _scrollY)
  //   console.log('это новая координата курсора ' + coordY)
  //   dot.current.style.top = coordY + 'px';
  //   //return dotY;
  // }

  const mouseMoveEvent = (e) =>{
    cursorVisible.current = true;
    toggleCursorVisibility();
    endX.current = e.pageX;
    endY.current = e.pageY;
    dot.current.style.top = endY.current + 'px';
    dot.current.style.left = endX.current + 'px';
    //setDotY(e.pageY);
  }

  // const updateCursorPosition = (e) =>{
  //   endX.current = e.pageX;
  //   endY.current = e.pageY;
  //   console.log('сработало обновление ' + (endY.current-window.scrollY));
  //   setDotY((endY.current-window.scrollY))
  //   return dotY;
  // }

  //console.log('это dotY ' + dotY)

  const animateCircule = () => {
    _x.current += (endX.current -_x.current)/delay;
    _y.current += (endY.current -_y.current)/delay;

    circule.current.style.top = _y.current + 'px';
    circule.current.style.left = _x.current + 'px';

    requestRef.current = requestAnimationFrame(animateCircule);
  }
  return (
    <div className="App">
          <div className="ipl-progress-indicator" id="ipl-progress-indicator">
            <div className='preloader-wrapper'>
              <div className="preloader-circule"></div>
              <div className="preloader-dot"></div>
              </div>

            </div>
            <div className="cursor-wrapper">
                <div ref={circule} className="custom-cursor cursor-auto"></div>
                <div ref={dot} className="custom-cursor cursor-auto-addition"></div>
            </div>
        <AppContent
          mouseOverEvent={mouseOverEvent}
          mouseOutEvent={mouseOutEvent}
        />

    </div>
  );
}

export default App;
