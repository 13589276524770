import img0 from "../components/image/volta/1.png";
import img1 from "../components/image/volta/2.png";
import img2 from "../components/image/volta/3.png";
import img3 from "../components/image/volta/4.png";
import img4 from "../components/image/volta/5.png";
import img5 from "../components/image/volta/6.png";
import img6 from "../components/image/volta/7.png";
import img7 from "../components/image/volta/8.png";
import img8 from "../components/image/volta/9.png";
import img9 from "../components/image/volta/10.png";
import img10 from "../components/image/volta/11.png";
import img11 from "../components/image/volta/12.png";
import img12 from "../components/image/volta/13.png";
import img13 from "../components/image/volta/14.png";
import img14 from "../components/image/volta/15.png";

export const volta = {
    id: 1,
    type: 'web',
    team: ['Татьяна Плющ'],
    title: 'volta',
    tags: '#сайт #volta #2023',
    cover: img0,
    img1: img1,
    img2: img2,
    img3: img3,
    img4: img4,
    img5: img5,
    img6: img6,
    img7: img7,
    img8: img8,
    img9: img9,
    img10: img10,
    img11: img11,
    img12: img12,
    img13: img13,
    img14: img14,
    color: '#10100F',
    textColor: '#FFF',
    mainTextColor: '#FF5D00',
    link: 'https://volta-moscow.ru/'
}