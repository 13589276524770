export const addWeb = [
    {
        id: 10,
        service: "цена за 1 экран (дизайн + верстка на Тильда)",
        price: 5000,
    },
    {
        id: 11,
        service: "создание 1 авторской иллюстрации",
        price: 3000,
    },
    {
        id: 12,
        service: "создание пакета иконок (12 шт)",
        price: 5000,
    },
    {
        id: 13,
        service: "перевод сайта на английский язык",
        price: 10000,
    },
    {
        id: 14,
        service: "подбор и обработка 1 изображения",
        price: 1000,
    },
    {
        id: 15,
        service: "написание текстов на сайт на 1 экран",
        price: 1000,
    },
    {
        id: 16,
        service: "создание анимации одного объекта",
        price: 5000,
    },
    {
        id: 17,
        service: "создание анимационного ролика на сайт за 1 секунду",
        price: 200,
    },
    {
        id: 18,
        service: "техподдержка «Tilda» в течение 1 месяца до 10 обращений",
        price: 10000,
    },
    {
        id: 19,
        service: "базовое обучение работы с «Tilda» до 3 консультаций",
        price: 5000,
    },
    {
        id: 20,
        service: "разработка 1 карточки товара",
        price: 1000,
    },
    {
        id: 21,
        service: "подключение базы данных (Airtable)",
        price: 10000,
    },
    {
        id: 22,
        service: "настройка личного кабинета",
        price: 10000,
    },
    {
        id: 23,
        service: "расширенная SEO-оптимизация",
        price: 5000,
    }
]


