import React from "react";
import { NavLink } from "react-router-dom";
import {routeDiscuss} from '../../pages/Discuss';


const Discuss = () => {

    const copyMail = (e) => {
        const mail = e.target.innerHTML;
        console.log(mail);
        navigator.clipboard.writeText(mail)
            .then(() => {
                alert('E-mail скоипрован в буфер обмена');
            })
            .catch(err => {
                console.error('Error in copying text: ', err);
            });
    }


    return(
            <section  className="discuss">
        <div  className="container">
            <h2  className="discuss-title">давайте обсудим <br></br> ваш проект</h2>
            <div  className="discuss-wrapper">
                <div  className="discuss-item">
                    Пару слов о бренде, <br></br> и мы приступим к созданию потрясающего дизайна
                </div>
                <div  className="discuss-item">
                    Мы <span  className="star">свяжемся</span> {'  '} с вами быстрее, чем гепард на адреналине
                </div>
                <div  className="discuss-item">
                    <p>Хотите без прелюдий?</p> 
                    <div  className="green" id="copy-mail" onClick={copyMail}>ne.studio.agency@gmail.com</div>

                    <div  className="star-desc">отвечаем: пн-сб, UTC +3</div>
                </div>
            </div>
            <NavLink to={routeDiscuss()} className='discuss-btn btn pointer'>
                <div  className="btn-text pointer">заказать</div> 
                <div  className="discuss-btn-img pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="86" height="34" viewBox="0 0 86 34">
                         <path  className="cls-1 pointer" d="M62.84.77c4.57,2.2,6.61,8.37,2.74,14.74a44,44,0,0,1-9.17,10.14A12.2,12.2,0,0,0,64.32,30c2.41.14,5.24-.91,8.35-3.83s6.36-7.6,9.52-14.34a2,2,0,1,1,3.62,1.7c-3.28,7-6.79,12.17-10.4,15.56S67.94,34.21,64.09,34a16.15,16.15,0,0,1-10.85-5.89,35,35,0,0,1-7.8,4.26c-3.13,1.17-6.47,1.67-9.37.51a7.53,7.53,0,0,1-4.21-4.49A19.68,19.68,0,0,1,30.8,22c-.07-2.25.05-4.7.18-7.06v-.21c.12-2.32.23-4.56.19-6.62-.06-3.29-1.07-3.95-1.28-4S29,4,28.34,4.87l0,0,0,0a67.15,67.15,0,0,0-6.84,10.58c-1.34,2.66-3.72,6.92-5.93,10.54-1.1,1.81-2.19,3.5-3.09,4.76a17.72,17.72,0,0,1-1.29,1.61,5,5,0,0,1-.66.63,2.68,2.68,0,0,1-1.29.57A2.15,2.15,0,0,1,7,32.47a3.05,3.05,0,0,1-.31-1.09,11.3,11.3,0,0,1,0-1.83c.07-1,.22-2.28.39-3.79.06-.54.12-1.12.19-1.71A118.06,118.06,0,0,0,8.09,7a4.75,4.75,0,0,0-.65-2.64.51.51,0,0,0-.53-.19A3.16,3.16,0,0,0,5.37,5,6.1,6.1,0,0,0,3.83,7.1a2,2,0,0,1-2.65,1,2,2,0,0,1-1-2.64A10.1,10.1,0,0,1,2.75,2,7.06,7.06,0,0,1,6.45.2a4.48,4.48,0,0,1,4.08,1.64,8.24,8.24,0,0,1,1.55,5,120,120,0,0,1-.83,17.62c0,.37-.08.72-.11,1.06.31-.49.63-1,1-1.55,2.17-3.55,4.49-7.72,5.77-10.26A70.85,70.85,0,0,1,25.18,2.42a5.41,5.41,0,0,1,6.23-2c2.49,1,3.7,3.87,3.77,7.66,0,2.2-.08,4.56-.2,6.83v.28c-.12,2.39-.23,4.67-.17,6.73a15.52,15.52,0,0,0,.79,5,3.56,3.56,0,0,0,2,2.23c1.55.62,3.74.48,6.48-.54a31.62,31.62,0,0,0,7-3.89c-2-4-2.59-9.18-.84-15.66,1-3.86,2.91-6.53,5.29-7.94A7.63,7.63,0,0,1,62.84.77ZM54.31,22.19c-1.21-2.94-1.62-6.9-.22-12.09.86-3.18,2.24-4.81,3.47-5.54a3.67,3.67,0,0,1,3.55-.19c2,1,4,4.3,1,9.06A39.4,39.4,0,0,1,54.31,22.19ZM10.66,31v0Z"/>
                    </svg>
                </div>        
                <div  className="btn-text pointer">простой дизайн</div> 
                </NavLink>
        </div>
    </section>
    )
}
export default Discuss