import React, {useEffect} from 'react';
import { NavLink } from 'react-router-dom';
import { routePrivacy } from '../../pages/Privacy';
import { routeMain } from '../../pages/Main';
import { useLocation } from "react-router-dom";


const Footer = () => {
    const { pathname } = useLocation();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
    return(
        <footer className="footer">
        <div className="container">
        <NavLink to={routeMain()} className='footer-img pointer'>
                                </NavLink>
            <h2 className="footer-desc">шедевры для вашего бизнеса</h2>
            <div className="footer-links-wrapper">
                <div className="footer-social-wrapper">
                <a href="https://www.behance.net/digitalne-stud" rel="nofollow noopener noreferrer" target="_blank" className="behance pointer"></a>
                  
                    <a href="https://www.instagram.com/ne.studio.agency/" rel="nofollow noopener noreferrer" target="_blank" className="instagram pointer"></a>
                    
                    <a href="https://wa.me/79613810324" rel="nofollow noopener noreferrer" target="_blank" className="wtsp pointer"></a>
                    
                    <a href="http://t.me/andrey_nestudio" rel="nofollow noopener noreferrer" target="_blank" className="telegram pointer"></a>
                    
                    <a href="https://vc.ru/u/1910604-ne-studio" rel="nofollow noopener noreferrer" target="_blank" className="linkedin pointer"></a>
                    
                    <a href="mailto:ne.studio.agency@gmail.com" className="mail pointer"></a>
                </div>
            </div>
            <div className="footer-privacy-wrapper">
                <div className="footer-year">2023</div>


                <div className="footer-policy">
                <NavLink to={routePrivacy()} className='pointer'>
                                    Политика конфиденциальности
                                </NavLink></div>
            </div>
        </div>
    </footer>
    )
}
export default Footer