import React, {useEffect, useState} from "react";
import { NavLink } from "react-router-dom";
import { routeMain } from "../Main";
import FooterNF from "../../components/FooterNF";

import './styles.scss';


const NotFound = () => {

    return(
        <section className="section section-notFound">

            <div className="container">
            <div className="section-title-wrapper">
                <NavLink to={routeMain()} className='arrow-home pointer'>
                </NavLink>
                </div>
                <p className="notFound-text">4<span className="logo"></span>4</p>
                <p className="notFound-desc">Упс! Такой страницы не существует.</p>
            </div>
          
    <FooterNF/>
    </section>
    )
}

export default NotFound;