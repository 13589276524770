import img0 from "../components/image/siteDariaPetrova/0.gif";
import img1 from "../components/image/siteDariaPetrova/1.png";
import img2 from "../components/image/siteDariaPetrova/2.png";
import img3 from "../components/image/siteDariaPetrova/3.png";
import img4 from "../components/image/siteDariaPetrova/4.png";
import img5 from "../components/image/siteDariaPetrova/5.gif";
import img6 from "../components/image/siteDariaPetrova/6.png";
import img7 from "../components/image/siteDariaPetrova/7.gif";
import img8 from "../components/image/siteDariaPetrova/8.gif";
import img9 from "../components/image/siteDariaPetrova/9.png";
import img10 from "../components/image/siteDariaPetrova/10.png";
import img11 from "../components/image/siteDariaPetrova/11.gif";

export const siteDariaPetrova = {
    id: 1,
    type: 'web',
    team: ['Татьяна Плющ', 'Елизавета Рудова', 'Анастасия Смирнова'],
    title: 'DARIA PETROVA',
    tags: '#сайт #лендинг #юрист #2023',
    desc: 'Дарья Петрова — практикующий корпоративный юрист, с современным и смелым подходом к работе. Проведены детальные исследования предметной области, а также прямых и косвенных конкурентов. Поэтому нетривиальная для юриспруденции цветовая гамма и геометрические визуальные решения стали основой разработки проекта.Сайт поддерживает образ серьёзного эксперта, подробно раскрывает опыт специалиста и показывает направления его практики.',
    cover: img0,
    img1: img1,
    img2: img2,
    img3: img3,
    img4: img4,
    img5: img5,
    img6: img6,
    img7: img7,
    img8: img8,
    img9: img9,
    img10: img10,
    img11: img11,
    color: '#f4f4f4',
    textColor: '#101010',
    link: 'https://dariapetrova-law.ru'
}