import React from "react";
import { useParams } from "react-router-dom";
import { NavLink } from "react-router-dom";


const PortfolioItem = ({item, textColor, goToAnother}) => {
    const {route} = useParams();
    return(

    <div  className={item.filter}>
            <div  className="portfolio-item-text">
                <div  className="portfolio-item-hashtag">{item.tags}</div>
                
                <NavLink to={`/${item.route}`} className='portfolio-item-title pointer' style={textColor?{color: textColor}:{}} onClick={goToAnother}>
                    {item.title}
                </NavLink>
                {/* <a className='portfolio-item-title pointer' href={item.link} target="_blank">{item.title}</a> */}
                
            </div>
                <NavLink to={`/${item.route}`} className='portfolio-cover pointer' style={{backgroundImage: `url(${item.cover})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                </NavLink>
                
                {/* <a className='portfolio-cover pointer' href={item.link} target="_blank">
                    <img src={item.cover} class="portfolio-cover-img"></img>
                </a> */}
                
                
        </div>

    )

}

export default PortfolioItem;