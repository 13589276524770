import React from "react";

const FormSlider2 = ({firstRadio, changeRadio, secondRadio, thirdRadio, fourthRadio, radioIsValid}) => {
    return(
    <>
                <div className="form-slider">
                    <div className="form-title-wrapper">
                        <h2 className="form-title-one">money, money,</h2>
                        <div className="form-desc-wrapper">
                            <h2 className="form-title-two">money</h2>
                            <p className="form-description">
                                Мы принимаем платежи в любой 
                                удобной для вас валюте из любых стран мира! 
                            </p>
                        </div>
                    </div>
                    <div className="form-body" id="radio-validate">
                        <legend className="form-legend">Укажите предполагаемый <span className="green">бюджет:</span></legend>
                        <div className="checkbox-wrapper radio-wrapper">
                            <input type="radio" id="1000" name="price" value="1000" className="pointer" checked={firstRadio} onChange={changeRadio}></input>
                            <label htmlFor="1000" className="pointer">менее 1000 $</label>
                            <input type="radio" id="1700" name="price" value="1700" className="pointer" checked={secondRadio} onChange={changeRadio}></input>
                            <label htmlFor="1700" className="pointer">до 1700 $</label>
                            <input type="radio" id="2700" name="price" value="2700" className="pointer" checked={thirdRadio} onChange={changeRadio}></input>
                            <label htmlFor="2700" className="pointer">почти 2700 $</label>
                            <input type="radio" id="3000" name="price" value="3000" className="pointer" checked={fourthRadio} onChange={changeRadio}></input>
                            <label htmlFor="3000" className="pointer">более 3000 $</label>
                        </div>
                        <div className="checkbox-error">{radioIsValid ? "" : "Выберите только один вариант"}</div>
                    </div>
                    
         
                </div>
    </>)
}
export default FormSlider2;