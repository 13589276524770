import React, {useEffect, useState} from "react";
import { MemoryRouter, NavLink } from "react-router-dom";
import { routeMain } from "../Main";
import routeDiscuss from "./routes";
import FooterDiscuss from "../../components/FooterDiscuss";
import FormSlider1 from "../../components/FormSlider1";
import FormSlider2 from "../../components/FormSlider2";
import FormSlider3 from "../../components/FormSlider3";
import FormSlider4 from "../../components/FormSlider4";
import './styles.css';
import { send } from 'emailjs-com';

// import './mail.php';


const DiscussPage = () => {

    const [index, setIndex] = useState(0);
    const nextSlide = () => {
        const newIndex=index+1;
        setIndex(newIndex);
        if (index == 3){
            setIndex(0);
        }
        return index;
    }

    const prevSlide = () => {
        const newIndex=index-1;
        setIndex(newIndex);
        if (index == 0){
            setIndex(3);
        }
        return index;        
    }

    const [desc, setDesc] = useState("");
    const [descIsValid, setDescIsValid] = useState();
    const [descColor, setDescColor] = useState("");
    const [name, setName] = useState("");
    const [nameIsValid, setNameIsValid] = useState();
    const [nameColor, setNameColor] = useState("");
    const [country, setCountry] = useState("");
    const [countryIsValid, setCountryIsValid] = useState();
    const [countryColor, setCountryColor] = useState("");
    const [utc, setUtc] = useState("UTC +3");
    // const [utcIsValid, setUtcIsValid] = useState();
    // const [utcColor, setUtcColor] = useState("");
    const [phone, setPhone] = useState("");
    const [phoneIsValid, setPhoneIsValid] = useState();
    const [phoneColor, setPhoneColor] = useState("");
    const [mail, setMail] = useState("");
    const [mailIsValid, setMailIsValid] = useState();
    const [mailColor, setMailColor] = useState("");

    const [logoChecked, setLogoChecked] = useState(false);
    const [siteChecked, setSiteChecked] = useState(false);
    const [motionChecked, setmotionChecked] = useState(false);
    const [marketingChecked, setMarketingChecked] = useState(false);
    const [otherChecked, setOtherChecked] = useState(false);
    const [checkBoxValue, setCheckBoxValue] = useState([]);

    const [policyChecked, setPolicyChecked] = useState(false);

    const [checkboxIsValid, setCheckboxIsValid] = useState();

    const [firstRadio, setFirstRadio] = useState(false);
    const [secondRadio, setSecondRadio] = useState(false);
    const [thirdRadio, setThirdRadio] = useState(false);
    const [fourthRadio, setFourthRadio] = useState(false);
    const [radioValue, setRadioValue] = useState('');

    const [radioIsValid, setRadioIsValid] = useState();

    const [success, setSuccess] = useState(false);

    const [errorMessage, setErrorMessage] = useState(false)

    const validateDesc = (desc) =>{
        if (desc.length > 10){
            setDescIsValid(true);
            setDescColor("green");
        } else {
            setDescIsValid(false);
            setDescColor("red");
        }
        return descIsValid, descColor;
    }
    const onDescChange = (e) => {
        let value = e.target.value;
        validateDesc(value);
        setDesc(value);
        return desc;
    }

    const validateName = (name) =>{
        if (name.length > 1){
            setNameIsValid(true);
            setNameColor("green");
        } else {
            setNameIsValid(false);
            setNameColor("red");
        }
        return nameIsValid, nameColor;
    }
    const onNameChange = (e) => {
        let value = e.target.value;
        validateName(value);
        setName(value);
        return name;
    }


    const validateCountry = (country) =>{
        if (country.length > 1){
            setCountryIsValid(true);
            setCountryColor("green");
        } else {
            setCountryIsValid(false);
            setCountryColor("red");
        }
        return countryIsValid, countryColor;
    }
    const onCountryChange = (e) => {
        let value = e.target.value;
        validateCountry(value);
        setCountry(value);
        return country;
    }
    
    // const validateUtc = (utc) =>{
    //     if (utc){
    //         setUtcIsValid(true);
    //         setUtcColor("green");
    //     } else {
    //         setUtcIsValid(false);
    //         setUtcColor("red");
    //     }
    //     return utcIsValid, utcColor;
    // }
    const onUtcChange = (e) => {
        let value = e.target.value;
        setUtc(value);
        return utc;
    }

    const validatePhone = (phone) =>{
        const phoneRegex =  /(?:\+|\d)[\d\-\(\) ]{9,}\d/g;
        if (phone.length >= 10 && phoneRegex.test(phone)){
            setPhoneIsValid(true);
            setPhoneColor("green");
        } else {
            setPhoneIsValid(false);
            setPhoneColor("red");
        }
        return phoneIsValid, phoneColor;
    }
    const onPhoneChange = (e) => {
        let value = e.target.value;
        validatePhone(value);
        setPhone(value);
        return phone;
    }

    const onPolicyChange = (e) => {
        if(e.target.checked){
            setPolicyChecked(true)
        } else {
            setPolicyChecked(false)
        }
        return policyChecked;
    }


    const validateMail = (mail) =>{
        const mailRegex = /\S+@\S+\.\S+/;
        if (mail.length > 1 && mailRegex.test(mail)){
            setMailIsValid(true);
            setMailColor("green");
        } else {
            setMailIsValid(false);
            setMailColor("red");
        }
        return mailIsValid, mailColor;
    }
    const onMailChange = (e) => {
        let value = e.target.value;
        validateMail(value);
        setMail(value);
        return mail;
    }

    const changeChecked = (e) => {
        if(e.target.checked){
            checkBoxValue.push(e.target.value);
        } 
        //console.log(checkBoxValue)
        let box = e.target.value;
        //console.log(e.target.checked)
        if (box == "logo"){
            setLogoChecked(!logoChecked);
            return logoChecked;
        } else if (box == "site"){
            setSiteChecked(!siteChecked);
            return siteChecked;
        } else if (box == "motion"){
            setmotionChecked(!motionChecked);
            return motionChecked;
        } else if (box == "marketing"){
            setMarketingChecked(!marketingChecked);
            return marketingChecked;
        } else if (box == "other"){
            setOtherChecked(!otherChecked);
            return otherChecked;
        }   
        return checkBoxValue;
    }

    useEffect(()=> {
        validateCheckbox();
    }, [logoChecked, siteChecked, motionChecked, marketingChecked, otherChecked])

    const validateCheckbox = () => {
        let arr = [logoChecked, siteChecked, motionChecked, marketingChecked, otherChecked];
        if (arr.join(',').includes('true')){
            setCheckboxIsValid(true)
        } else {
            setCheckboxIsValid(false)
        }
        return checkboxIsValid;
    }

    const changeRadio = (e) => {
        let radio = e.target.value;
        if (radio == "1000"){
            setFirstRadio(true);
            setSecondRadio(false);
            setThirdRadio(false);
            setFourthRadio(false);
        } else if (radio == "1700"){
            setFirstRadio(false);
            setSecondRadio(true);
            setThirdRadio(false);
            setFourthRadio(false);
            
        } else if (radio == "2700"){
            setFirstRadio(false);
            setSecondRadio(false);
            setThirdRadio(true);
            setFourthRadio(false);
            
        } else if (radio == "3000"){
            setFirstRadio(false);
            setSecondRadio(false);
            setThirdRadio(false);
            setFourthRadio(true); 
        }  
        setRadioValue(radio);
        return firstRadio, secondRadio, thirdRadio, fourthRadio, radioValue;
    }
    useEffect(()=> {
        validateRadio();
    }, [firstRadio, secondRadio, thirdRadio, fourthRadio])

    const validateRadio = () => {
        let arr = [firstRadio, secondRadio, thirdRadio, fourthRadio];
        if (arr.join(',').includes('true')){
            setRadioIsValid(true)
        } else {
            setRadioIsValid(false)
        }
        return radioIsValid
    }

    const ajaxSend = async (formData) => {
        const response = await fetch("http:/nestudi7.beget.tech/public_html/send.php", {
            method: "POST",
            body: formData
        });
        if (!response.ok) {
            throw new Error(`Ошибка по адресу, статус ошибки ${response.status}`);
        }
        return await response.text();
    };
    // const ajaxSend = async (formData) => {
    //     fetch("./handler.php", { // файл-обработчик
    //         method: "POST",
    //         headers: {
    //             "Content-Type": "application/json", // отправляемые данные
    //         },
    //         body: JSON.stringify(formData)
    //     })
    //         .then(response => alert("Сообщение отправлено"))
    //         .catch(error => console.error(error))
    // };

function submitForm (e) {
    e.preventDefault();
    if (descIsValid && 
        nameIsValid && 
        countryIsValid && 
        phoneIsValid && 
        mailIsValid && 
        checkboxIsValid && 
        radioIsValid &&
        policyChecked){

    let formData ={
        service: checkBoxValue,
        price: radioValue,
        desc: desc,
        name: name,
        country: country,
        utc: utc,
        phone: phone,
        mail: mail
    };
    setErrorMessage(false)
    //console.log(formData);

    send(
        'service_gxxyipr',
        'template_o6rigkk',
        formData,
        'BJ0ebDnqpYkioYHDj'
      )
        .then((response) => {
          console.log('SUCCESS!', response.status, response.text);
          setSuccess(true);
        })
        .catch((err) => {
          console.log('FAILED...', err);
          setSuccess(false);
        });
    } else {
        setErrorMessage(true);

    }
    return errorMessage
}


function handleSubmit (e) {
    e.preventDefault();
        if (descIsValid && 
            nameIsValid && 
            countryIsValid && 
            phoneIsValid && 
            mailIsValid && 
            checkboxIsValid && 
            radioIsValid){

        let formData =JSON.stringify({
            desc: desc,
            name: name,
            country: country,
            utc: utc,
            phone: phone,
            mail: mail,
        });
        let form = e.target;

        // let formData = new FormData(form);
        //         formData = Object.fromEntries(formData);
                ajaxSend(formData)
                    .then((response) => {
                        console.log(formData);
                        setSuccess(true);
                        form.reset();
                    })
                    .catch((err) => console.error(err))

        // let formData = new FormData(form)
        //     let response = await fetch(form.action, {
        //         method: form.method,
        //         headers: {
        //             'Content-Type': 'application/json',
        //         },
        //         body: JSON.stringify(formData)

        //     });
        //     if (response.ok){
        //         setSuccess(true);
        //         let result = await response.json();
        //         alert (result.message);
        //         form.reset(); 
        //     } else {
        //         alert("Ошибка при отправке формы")
        //         setSuccess(false);
        //     }

        } else {
            alert ("Форма заполнена некорректно")
            setSuccess(false);
        }
        return success
        
    }

    const removeSuccess = () => {
        setSuccess(false);
        return success;
    }


    return(
        <>

    <section className="form">
        
        <div className="container"> 
            
            <div className="form-slider-wrapper">
            <NavLink to={routeMain()} className='arrow-home pointer'>
                                </NavLink>
                <form className="form" id="form" autoComplete="off" encType="multipart/form-data" onSubmit={submitForm} method="POST">
                     {index==0? <FormSlider1
                        changeChecked={changeChecked}
                        logoChecked={logoChecked}
                        siteChecked={siteChecked}
                        motionChecked={motionChecked}
                        marketingChecked={marketingChecked}
                        otherChecked={otherChecked}
                        checkboxIsValid={checkboxIsValid}
                     /> : 
                        index==1? <FormSlider2
                            firstRadio={firstRadio}
                            secondRadio={secondRadio}
                            thirdRadio={thirdRadio}
                            fourthRadio={fourthRadio}
                            changeRadio={changeRadio}
                            radioIsValid={radioIsValid}
                        /> :
                        index==2? <FormSlider3
                            desc = {desc}
                            onDescChange={onDescChange}
                            descColor={descColor}
                        /> :
                        index==3? <div className="form-slider">
                        <FormSlider4
                            name={name}
                            country={country}
                            utc={utc}
                            phone={phone}
                            mail={mail}
                            onNameChange={onNameChange}
                            nameColor={nameColor}
                            onCountryChange={onCountryChange}
                            countryColor={countryColor}
                            onUtcChange={onUtcChange}
                            onPhoneChange={onPhoneChange}
                            phoneColor={phoneColor}
                            onMailChange={onMailChange}
                            mailColor={mailColor}
                            onPolicyChange={onPolicyChange}
                            policyChecked={policyChecked}
                        />
                        <div className="form-body" style={{marginTop: '0'}}>
                        <button type="submit" className="submit-button btn pointer">Отправить</button></div></div> :
                        ""                     
                     }
                     <div className={errorMessage ? 'error-message shown' : 'error-message'}>
                        <p>Форма заполнена некорректно, пожалуйста, заполните все обязательные поля формы</p>
                     </div>
                
            </form>
            <div className={success ? "submit-success-wrapper shown" : "submit-success-wrapper" }>
                <div className="overlay pointer" onClick={removeSuccess}></div>
                <div className="submit-success">
                    <h2 className="success-title">спасибо!</h2>
                <p className="success-text">Ваш запрос уже обрабатывается!</p>
                <p className="success-text">Мы уже подбираем «тот самый» цвет для вашего бренда</p>
                <div className="success-social">
                    <a href="https://www.instagram.com/ne.studio.agency/" rel="nofollow noopener noreferrer" target="_blank" className="instagram"></a>
               
                    <a href="https://www.behance.net/digitalne-stud" rel="nofollow noopener noreferrer" target="_blank" className="behance"></a>
             
                </div>

                <NavLink to={routeMain()} className='success-button btn pointer'>
                              на главную  </NavLink>
            </div>
                
            </div>
            
            <div className="slider-arrow">
                <div className={index == 3 ? " arrow-next_form arrow unshown pointer" : "arrow-next_form arrow pointer"} id="arrow-next" onClick={nextSlide}></div>
                <div className={index == 0 ? " arrow-prev_form arrow unshown pointer" : "arrow-prev_form arrow pointer"} id="arrow-prev" onClick={prevSlide}></div>
            </div>

            <div className="slider-dots">
                <div className={index == 0 ? "dot active":"dot"}></div>
                <div className={index == 1 ? "dot active":"dot"}></div>
                <div className={index == 2 ? "dot active":"dot"}></div>
                <div className={index == 3 ? "dot active":"dot"}></div>
            </div>
            </div> 

        </div>
        
    </section>
    <FooterDiscuss/>
    </>

        
    )

}

export {routeDiscuss};
export default DiscussPage;
