import img0 from "../components/image/logunova/0.gif";
import img1 from "../components/image/logunova/1.png";
import img2 from "../components/image/logunova/2.gif";
import img3 from "../components/image/logunova/3.gif";
import img4 from "../components/image/logunova/4.png";
import img5 from "../components/image/logunova/5.png";
import img6 from "../components/image/logunova/6.png";
import img7 from "../components/image/logunova/7.png";
import img8 from "../components/image/logunova/8.png";
import img9 from "../components/image/logunova/9.png";
import img10 from "../components/image/logunova/10.png";
import slide1 from "../components/image/logunova/guide/1.webp";
import slide2 from "../components/image/logunova/guide/2.webp";
import slide3 from "../components/image/logunova/guide/3.webp";
import slide4 from "../components/image/logunova/guide/4.webp";
import slide5 from "../components/image/logunova/guide/5.webp";
import slide6 from "../components/image/logunova/guide/6.webp";
import slide7 from "../components/image/logunova/guide/7.webp";
import slide8 from "../components/image/logunova/guide/8.webp";
import slide9 from "../components/image/logunova/guide/9.webp";
import slide10 from "../components/image/logunova/guide/10.webp"; 
import slide11 from "../components/image/logunova/guide/11.webp";
import slide12 from "../components/image/logunova/guide/12.webp";
import slide13 from "../components/image/logunova/guide/13.webp";
import slide14 from "../components/image/logunova/guide/14.webp";
import slide15 from "../components/image/logunova/guide/15.webp";
import slide16 from "../components/image/logunova/guide/16.webp";
import slide17 from "../components/image/logunova/guide/17.webp";
import slide18 from "../components/image/logunova/guide/18.webp";
import slide19 from "../components/image/logunova/guide/19.webp";
import slide20 from "../components/image/logunova/guide/20.webp";
import slide21 from "../components/image/logunova/guide/21.webp";
import slide22 from "../components/image/logunova/guide/22.webp";
import slide23 from "../components/image/logunova/guide/23.webp";
import slide24 from "../components/image/logunova/guide/24.webp";
import slide25 from "../components/image/logunova/guide/25.webp";
import slide26 from "../components/image/logunova/guide/26.webp";
import slide27 from "../components/image/logunova/guide/27.webp";
import slide28 from "../components/image/logunova/guide/28.webp";



export const logunova = {
    id: 1,
    type: 'graph',
    team: ['Андрей Миланин', 'Дарья Рогова', 'Семен Елеев', 'Анна Царькова', 'Татьяна Хахаева', 'Екатерина Прокопенко'],
    title: 'Вера Логунова',
    tags: '#лого #фирменныйстиль #коучпсихолог #2023',
    desc: 'Вера Логунова — кейс про разработку бренда для коуча и психолога. Вера работает по методу экспоненциального коучинга, проводя групповые и индивидуальные занятия. Коучинг — прогрессивная сфера, где меньше слов и больше дела. Логотип и фирменный стиль получились простые и понятные, даже краткие: инициалы ЛВ завернуты в форму графика роста и поворотов на жизненном пути.',
    cover: img0,
    img1: img1,
    img2: img2,
    img3: img3,
    img4: img4,
    img5: img5,
    img6: img6,
    img7: img7,
    img8: img8,
    img9: img9,
    img10: img10,
    slider: [
        slide1,
        slide2,
        slide3,
        slide4,
        slide5,
        slide6,
        slide7,
        slide8,
        slide9,
        slide10,
        slide11,
        slide12,
        slide13,
        slide14,
        slide15,
        slide16,
        slide17,
        slide18,
        slide19,
        slide20,
        slide21,
        slide22,
        slide23,
        slide24,
        slide25,
        slide26,
        slide27,
        slide28
    ],
    color: '#101010',
    textColor: '#fff',
}