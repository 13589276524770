import img0 from "../components/image/logoDariaPetrova/0.gif";
import img1 from "../components/image/logoDariaPetrova/1.gif";
import img2 from "../components/image/logoDariaPetrova/2.png";
import img3 from "../components/image/logoDariaPetrova/3.gif";
import img4 from "../components/image/logoDariaPetrova/4.png";
import img5 from "../components/image/logoDariaPetrova/5.png";
import img6 from "../components/image/logoDariaPetrova/6.png";
import img7 from "../components/image/logoDariaPetrova/7.png";
import img8 from "../components/image/logoDariaPetrova/8.gif";


export const logoDariaPetrova = {
    id: 1,
    type: 'graph',
    team: ['Андрей Миланин', 'Дарья Рогова', 'Семен Елеев', 'Анна Царькова', 'Татьяна Хахаева', 'Екатерина Прокопенко'],
    title: 'DARIA PETROVA',
    tags: '#лого #фирменныйстиль #юрист #2022',
    desc: 'Для кейса практикующего и уверенного в себе юриста Дарьи Петровой был разработан леттеринговый логотип. Знак объединяет в себе две буквы: «D», «P» (инициалы клиентки и ее жизненный девиз “doctrine and practice”) в символ жизненной силы и знак спирали, которая показывает процесс цикличности и растущей уверенности в результате. Точка в композиционном конце символизирует завершенность процесса.',
    cover: img0,
    img1: img1,
    img2: img2,
    img3: img3,
    img4: img4,
    img5: img5,
    img6: img6,
    img7: img7,
    img8: img8,
    color: '#f4f4f4',
    textColor: '#101010',
    mainTextColor: '#d2973b'
}