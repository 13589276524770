import React, { useEffect, useState } from "react";
import Cookies from 'js-cookie';
import { NavLink } from "react-router-dom";
import { routePrivacy } from '../../pages/Privacy';
import './styles.css'

const CookieBar = () => {
    const [isCookieSet, setCookie] = useState(Cookies.get("cookieConsent"));

    // const [isCookieSet, setCookie] = useState(false);
    // console.log(isCookieSet)

    // Function to handle accepting cookies
    const handleAcceptCookies = () => {
        const expirationDate = new Date();
        expirationDate.setFullYear(expirationDate.getFullYear() + 1);
        Cookies.set("cookieConsent", true, { expires: expirationDate });
        setCookie(true);
    };

    // Function to handle rejecting cookies
    const handleRejectCookies = () => {
        Cookies.remove("cookieConsent");
        setCookie(false);
    };

   

    return(
        <div className="container">
            <div id="cookie_note" style={{display: isCookieSet ? 'none' : 'block'}}>
                <div className="cookie_note-wrapper">
                <p>Мы используем файлы cookies для улучшения работы сайта. Оставаясь на нашем сайте, вы соглашаетесь с условиями
                    использования файлов cookies. Чтобы ознакомиться с нашей Политикой конфиденциальности, 
                    <NavLink to={routePrivacy()} className='pointer'>
                        нажмите здесь
                                </NavLink>.</p>
                <button className="button cookie_accept btn btn-primary btn-sm" onClick={handleAcceptCookies}>Я согласен</button>
                </div>
            </div>
        </div>
    )
}

export default CookieBar;
