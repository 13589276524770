import img1 from "../components/image/ingresso/1.png";
import img2 from "../components/image/ingresso/2.png";
import img3 from "../components/image/ingresso/3.png";


export const ingresso = {
    id: 1,
    type: 'web',
    team: ['Татьяна Плющ'],
    title: 'Ingresso',
    tags: '#сайт #лендинг #дизайнИнтерьера #2023',
    img1: img1,
    img2: img2,
    img3: img3,
    color: '#FFFCF7',
    textColor: '#000114',
    mainTextColor: '#FFD923',
    link: 'https://ingressointeriors.ru/'
}