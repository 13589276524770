import gf1 from "../components/image/portrait-bw/g-foto1.png";
import gf2 from '../components/image/portrait-bw/g-foto2.png';
import gf3 from '../components/image/portrait-bw/g-foto3.png';
import gf4 from '../components/image/portrait-bw/g-foto4.png';
import gf5 from '../components/image/portrait-bw/g-foto5.png';
import gf6 from '../components/image/portrait-bw/g-foto6.png';


import gf1C from "../components/image/portrait-color/g-foto1.png";
import gf2C from "../components/image/portrait-color/g-foto2.png";
import gf3C from "../components/image/portrait-color/g-foto3.png";
import gf4C from "../components/image/portrait-color/g-foto4.png";
import gf5C from "../components/image/portrait-color/g-foto5.png";
import gf6C from "../components/image/portrait-color/g-foto6.png";

  export const graphDesigners = [
    {
      id: 0,
      name: 'Андрей Миланин',
      position: <>Директор <br/> по межпланетной коммуникации</>,
      img: gf1,
      portrait: gf1C,
    },
    {
      id: 1,
      name: 'Анна Царькова',
      position: <>Дизайнер уникальных логотипов для кофейни "Взбитые бабки"</>,
      img: gf2,
      portrait: gf2C,
    },
    {
      id: 2,
      name: 'Дарья Рогова',
      position: <>Шеф-дизайнер логотипов для студии массажа морских звезд</>,
      img: gf3,
      portrait: gf3C,
    },
    {
      id: 3,
      name: 'Елизавета Пенкина',
      position: <>Виртуальная колдунья по разработке уникального контента и текстов <br/> для социальных сетей</>,
      img: gf4,
      portrait: gf4C,
    },
    {
      id: 4,
      name: 'Татьяна Хахаева',
      position: <>Король магического контента и создания вирусных видео-роликов</>,
      img: gf6,
      portrait: gf6C,
    }
  ]

