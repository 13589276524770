import wf1 from "../components/image/portrait-bw/v-foto1.png";
import wf2 from '../components/image/portrait-bw/v-foto2.png';
import wf3 from '../components/image/portrait-bw/v-foto3.png';
import wf4 from '../components/image/portrait-bw/v-foto4.png';
import wf5 from '../components/image/portrait-bw/v-foto5.png';
import wf6 from '../components/image/portrait-bw/v-foto6.png';
import wf7 from '../components/image/portrait-bw/v-foto7.png';
import wf8 from '../components/image/portrait-bw/v-foto8.png';

import wf1C from "../components/image/portrait-color/v-foto1.png";
import wf2C from "../components/image/portrait-color/v-foto2.png";
import wf3C from "../components/image/portrait-color/v-foto3.png";
import wf4C from "../components/image/portrait-color/v-foto4.png";
import wf5C from "../components/image/portrait-color/v-foto5.png";
import wf6C from "../components/image/portrait-color/v-foto6.png";
import wf7C from "../components/image/portrait-color/v-foto7.png";
import wf8C from "../components/image/portrait-color/v-foto8.png";



export const webDesigners = [
    {
      id: 0,
      name: 'Татьяна Плющ',
      position: <>Консультант <br/> по виртуальному лечению сайтов <br/>и онлайн-консультациям</>,
      img: wf1,
      portrait: wf1C,
    },
    {
      id: 1,
      name: 'Ксения Миланина',
      position: <>Шаман веб-программирования и оптимизации сайтов</>,
      img: wf4,
      portrait: wf4C,
    },
    {
      id: 2,
      name: 'Елизавета Рудова',
      position: <>Архимаг по созданию виртуальных миров</>,
      img: wf5,
      portrait: wf5C,
    },  
    {
      id: 3,
      name: 'Ольга Калафат',
      position: <>Магистр веб-дизайна <br/> и бросания чар</>,
      img: wf8,
      portrait: wf8C,
    },
    {
      id: 4,
      name: 'Анастасия Смирнова',
      position: <>Директор по разработке дизайна сайтов <br/> для драконов</>,
      img: wf7,
      portrait: wf7C,
    },     
    {
      id: 5,
      name: 'Антонина Стефановская',
      position: <>Директор по взрывам мозга и сотрясению воображения</>,
      img: wf3,
      portrait: wf3C,
    },
  ]