import img0 from "../components/image/mossman/1.png";
import img1 from "../components/image/mossman/2.png";
import img2 from "../components/image/mossman/3.png";
import img3 from "../components/image/mossman/4.png";
import img4 from "../components/image/mossman/5.png";
import img5 from "../components/image/mossman/6.png";
import img6 from "../components/image/mossman/7.png";
import img7 from "../components/image/mossman/8.png";
import img8 from "../components/image/mossman/9.png";
import img9 from "../components/image/mossman/10.png";
import img10 from "../components/image/mossman/11.png";
import img11 from "../components/image/mossman/12.png";
import img12 from "../components/image/mossman/13.png";
import img13 from "../components/image/mossman/14.png";
import img14 from "../components/image/mossman/15.png";
import img15 from "../components/image/mossman/16.png";


export const mossman = {
    id: 1,
    type: 'web',
    team: ['Татьяна Плющ', 'Ольга Калафат', 'Елизавета Рудова', 'Анастасия Смирнова'],
    title: 'mossman',
    tags: '#сайт #mossman #2023',
    cover: img0,
    img1: img1,
    img2: img2,
    img3: img3,
    img4: img4,
    img5: img5,
    img6: img6,
    img7: img7,
    img8: img8,
    img9: img9,
    img10: img10,
    img11: img11,
    img12: img12,
    img13: img13,
    img14: img14,
    img15: img15,
    color: '#272627',
    textColor: '#EFDB9C',
    link: 'https://mossman.ru/'
}