import React from "react";


const GraphDis = ({item, style, onClick}) => {
    return(
        <img  className="gallery-item-small gallery-item pointer" src={item.img} style={style ? style : {}} onClick={onClick}></img>
    )

}

export default GraphDis;