import React, { useEffect } from 'react';
import {useState} from 'react';
import { NavLink } from 'react-router-dom';
import $ from 'jquery';
import { routeMain} from '../../pages/Main';
import { routePortfolio } from '../../pages/Portfolio';
import { routeAbout } from '../../pages/About';
import { routeDiscuss } from '../../pages/Discuss';
// import { routeMain as routeMainPage} from '../../pages/MainPage';
// import { routeMain as routeSearchPage} from '../../pages/Search';
// import { routeMain as routeCategoriesPage} from '../../pages/Categories';
// import { routeMain as routeAboutPage} from '../../pages/About';
import './styles.css';

const Header = () => {

const [menuActive, setMenuActive] = useState(false);

const menuOpen = (e)=> {
    setMenuActive(true)
    const onClick = e => {
        if (!e.target.className.includes('menu') || e.target.className.includes('menu-icon-transform')){
            setMenuActive(false)
        } else if (e.target.className.includes('menu-item-title')){
          setMenuActive(false)
        } else if (e.target.className.includes('menu-btn')){
          setMenuActive(false)
        }
    }
    document.addEventListener('click', onClick);
    return () => document.removeEventListener('click', onClick);
    
}  

return (
      <header className="mainHeader header">
        <div className={menuActive ? 'menu-wrapper menu-wrapper-transform' : 'menu-wrapper'} onClick={menuOpen}>    
            <div className="menu-subwrapper">      
                <div className={menuActive ? 'menu-icon menu-icon-transform pointer' : 'menu-icon'} id="menu-icon"></div> 
                <div className={menuActive ? "menu-preview" : "menu-preview pointer"}>
                    <nav className={menuActive ? 'navigation show' : 'navigation'}>
                        <div className="social-wrapper">
                            <a href="https://www.behance.net/digitalne-stud" rel="nofollow noopener noreferrer" target="_blank" className="behance pointer"></a>
                        
                            <a href="https://www.instagram.com/ne.studio.agency/" rel="nofollow noopener noreferrer" target="_blank" className="instagram pointer"></a>
                        
                            <a href="https://wa.me/79613810324" rel="nofollow noopener noreferrer" target="_blank" className="wtsp pointer"></a>
                            
                            <a href="http://t.me/andrey_nestudio" rel="nofollow noopener noreferrer" target="_blank" className="telegram pointer"></a>
                            
                            <a href="https://vc.ru/u/1910604-ne-studio" rel="nofollow noopener noreferrer" target="_blank" className="linkedin pointer"></a>
                            
                            <a href="mailto:ne.studio.agency@gmail.com" target="_blank" className="mail pointer"></a>
                            
                        </div>
                        <ul className="menu-list">
                                
                            <li className="menu-item">
                                <NavLink to={routeMain()} className='menu-item-title h2 pointer'>
                                            Главная
                                </NavLink>
                            
                            </li>
                            <li className="menu-item">
                            <NavLink to={routePortfolio()} className='menu-item-title h2 pointer'>
                                            портфолио
                                </NavLink> 
                                </li>
                            <li className="menu-item">
                            <NavLink to={routeAbout()} className='menu-item-title h2 pointer'>
                                            об агентстве
                                </NavLink> 
                              </li>
                              <NavLink to={routeDiscuss()} className='menu-btn btn pointer'>
                                    обсудить проект
                                </NavLink> 
                        </ul>
                    </nav>
                </div>
            </div>
            </div>
      </header>
    );
  }
  
  export default Header;