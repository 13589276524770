

export const aboutSlider = [
    {
        id: 0,
        title: 'логотипы',
        body: 'У нас команда творческих гениев, создающих логотипы, от которых ваши конкуренты зеленеют от зависти',
        starClass: 'slider-star-logo slider-star'
    },
    {
        id: 1,
        title: 'Сайты',
        body: 'Лёгкие и ненавязчивые лендинги, многостраничные шедевры, интернет-магазины и все остальное',
        starClass: 'slider-star-site slider-star'
    },
    {
        id: 2,
        title: 'фирменный стиль',
        body: 'Утончённый, как шедевр минимализма, или сложный и уникальный, как узор для вязания у вашей бабушки в виде единорога',
        starClass: 'slider-star-brand slider-star'
    },
    {
        id: 3,
        title: 'моушен-дизайн',
        body: 'Креативность, талант и технические навыки - заставляют двигаться объекты так, чтобы зрители забывали о реальном мире',
        starClass: 'slider-star-motion slider-star'
    },
    {
        id: 4,
        title: 'маркетинг',
        body: 'Проще говоря, если вы хотите увеличить свой доход и привлечь новых клиентов, то этим мы и занимаемся',
        starClass: 'slider-star-marketing slider-star'
    }

]