import React from 'react';
import { NavLink } from 'react-router-dom';
import { routePrivacy } from '../../pages/Privacy';


const FooterDiscuss = () => {
    return(
        <footer className="footer-form footer">
        <div className="container">
            <div className="footer-privacy-wrapper">
            <div className="footer-year">2023</div>
            
            <div className="footer-policy">
                <NavLink to={routePrivacy()} className=''>
                                    Политика конфиденциальности
                                </NavLink></div>
            </div>
        </div>
    </footer>
    )
}
export default FooterDiscuss;